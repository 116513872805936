/*THIS IS TEMP FOR TESTING ONLY */
/* fixme steve - reduce number page and feature specifc class names */
/* fixme should have single class name for each global element */

@import "~jsoneditor/dist/jsoneditor.min.css";

.navbar {
    background-color: #0f144b;
    color: #fff;
    text-transform: uppercase;
    border-color: #e7e7e7;
    font-weight: bold;
    font-size: medium;
}
nav a { color:#fff;}
nav a.active { color:#00609c;}

.app-sidebar {display:none}

@media only screen and (min-width: 768px) {

    .home-body .login {
        padding-bottom: 30px;
        max-width:420px
    }

    .home-body .login .btn-success {
        width: 100%
    }

}

@media only screen and (min-width: 992px) {

}


/*END of CELS css added*/


/*CSS from anl.gov*/
/*START*/
/*TEMP until figure out what styles we want to grab from Argonne Site..*/



.collection-list, .footer-address__list, .footer-nav-list, .footer-nav-sublist, .glossary-nav__list, .link-listing__list, .list--simple, .listing-view-list, .nav-list, .nav-sublist, .profile-reference-list, .rail-list, .rail-nav-list, .rail-nav-sublist, .related-main__list, .social-media__list, .taxonomy-listing__level3, .taxonomy-listing__level4, .taxonomy-listing__list, .utility-nav__list {
    margin: 0;
    padding: 0;
    list-style: none
}

.app-header__block, .app-header__title-block, .article-footer, .basic-header__title-block, .clearfix, .collection, .contact, .contact--main, .contact__block, .contacts-detail, .container, .curated-block, .form-row, .forum-topic .action-links, .full-width-teaser, .key-info, .key-info__block, .profile-header__block, .project-teaser__block, .rail-paragraph .rail-block .profile-group, .rail-paragraph .rail-block .text-list, .related-main, .related-teaser, .section-center, .section-clear, .section-divider--border .section-divider__container, .section-divider--overlap .section-divider__container, .section-divider__buttons, .section-search-container, .section-spacing, .share-block, .simple-teaser-list-wrapper, .site-search, .teaser--image, .text-overlay, .utility-nav {
    clear: both
}

.app-header__block:after, .app-header__title-block:after, .article-footer:after, .basic-header__title-block:after, .clearfix:after, .collection:after, .contact--main:after, .contact:after, .contact__block:after, .contacts-detail:after, .container:after, .curated-block:after, .form-row:after, .forum-topic .action-links:after, .full-width-teaser:after, .key-info:after, .key-info__block:after, .profile-header__block:after, .project-teaser__block:after, .rail-paragraph .rail-block .profile-group:after, .rail-paragraph .rail-block .text-list:after, .related-main:after, .related-teaser:after, .section-center:after, .section-clear:after, .section-divider--border .section-divider__container:after, .section-divider--overlap .section-divider__container:after, .section-divider__buttons:after, .section-search-container:after, .section-spacing:after, .share-block:after, .simple-teaser-list-wrapper:after, .site-search:after, .teaser--image:after, .text-overlay:after, .utility-nav:after {
    content: "";
    display: table;
    clear: both
}

.element-focusable, .element-invisible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px)
}

.element-focusable:active, .element-focusable:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto
}

*, :after, :before {
    box-sizing: border-box
}

html {
    background: #fff;
    color: #333;
    font-size: 100%;
    line-height: 1.5em;
    font-family: proxima-nova, Helvetica, Arial, Nimbus Sans L, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -.0135em
}


@media (min-width: 900px) {
    html .cels {
        font-size: 110%;
    }
    body.cels.explorer-page {background: linear-gradient(90deg, #f9f9f9 280px, #fff 0%)}


    .cels.explorer-page .col-tree {width: 280px;float: left;}
    .cels.explorer-page .col-form {margin:0 0 0 300px;}
    .cels .user-explorer .col-tree {width: 250px;}
    .cels .user-explorer .col-form {margin-left:270px;width:75%}
    .cels textarea {font-size:100%;padding-bottom:20px}
    .container {
        width: 90%;
        padding: 0;
    }
}

@media (min-width: 1100px) {
    html .cels{
        font-size: 120%;

    }
    body.cels {
        margin-bottom: 115px;
    }
    .cels.explorer-page .col-tree {width: 300px}
    .cels.explorer-page .col-form {margin:0 0 0 320px;}
    body.cels.explorer-page {background: linear-gradient(90deg, #f9f9f9 300px, #fff 0%)
    }


    body.cels.explorer-page .user-explorer .acctInfo {background: linear-gradient(90deg, #f9f9f9 300px, #fff 0%);min-height:700px;
    }
    .cels textarea {font-size:90%;padding-bottom:20px}

    .container {
        max-width: 1250px;
    }
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
    display: block
}

audio, canvas, progress, video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

progress {
    vertical-align: baseline
}

[hidden], template {
    display: none
}

a {
    transition: all .3s ease;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    color: #00609c;
    text-decoration: none
}

a:active, a:hover {
    outline-width: 0;
    color: #004169;
    text-decoration: underline
}

a:focus {
    outline-color: #00609c
}

a[href^=mailto] span.email-address {
    text-transform: lowercase !important
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

/*b, strong {
    font-weight: inherit;
    font-weight: bolder
}*/

code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 1em
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2.2em;
    line-height: 1.15909em;
    margin-top: .85227em;
    margin-bottom: .34091em;
    letter-spacing: -1px
}

h1, h2 {
    font-weight: 600
}

h2 {
    font-size: 1.6em;
    line-height: 1.14474em;
    margin-top: .98684em;
    margin-bottom: .39474em;
    display:inline-block;
}

/*h3 {
    font-size: 1.57em;
    line-height: 1.19427em;
    margin-top: .95541em;
    margin-bottom: .47771em;
    font-weight: 600
}*/

h4 {
    font-size: 1.26em;
    line-height: 1.30952em;
    /*margin-top: 1.19048em;*/
    margin-bottom: .59524em
}

h5 {
    font-size: 1em;
    line-height: 1.35em;
    margin-top: 1.125em;
    margin-bottom: .375em
}

h6 {
    font-size: .85em;
    line-height: 1.41176em;
    margin-top: 1.32353em;
    margin-bottom: .44118em
}

mark {
    background-color: #f8b200;
    color: #000
}

small {
    font-size: .85em;
    line-height: 1.41176em;
    display: inline-block
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    max-width: 100%;
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

blockquote {
    margin: 1.5em
}

dl, menu, ol, ul {
    margin: .375em 0
}

main dl, main ol, main ul {
    margin: 0 0 1.5em
}

main p + ul {
    margin-top: -.75em
}

main ol li, main ul li {
    line-height: 1.2em;
    margin-bottom: .45em
}

ol ol, ol ul, ul ol, ul ul {
    margin: 0 0 .75em
}

dd {
    margin: 0 0 0 1em
}

menu, ol, ul {
    padding: 0 0 0 1em
}

figure {
    margin: 1.5em
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

p, pre {
    margin: 0 0 1.5em
}

button, input, optgroup, select, textarea {
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button, select {
    text-transform: none
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
    outline: 1px dotted ButtonText
}

input {
    overflow: visible
}

[type=checkbox], [type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: .54
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

fieldset {
    border: 1px solid hsla(0, 0%, 100%, .6);
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    box-sizing: border-box;
    display: table;
    max-width: 100%;
    white-space: normal;
    color: inherit;
    padding: 0
}

optgroup {
    font-weight: 700
}

textarea {
    overflow: auto
}

.container {
    width: 100%;
    min-width: 260px;
    padding: 0 1.5em;
    margin: 0 auto
}

@media (min-width: 900px) {
    .container, .main-nav-container {
        width: 90%;
        padding: 0
    }

}

/*@media (min-width: 1100px) {
    .container {
        max-width: 1250px
    }
}*/

@media (min-width: 900px) {
    .field--body-paragraph-bottom .section-spacing .container, .field--body-paragraph-bottom .section-spacing .container .section-spacing .container, .field--body-paragraph-top .section-spacing .container, .field--body-paragraph-top .section-spacing .container .section-spacing .container {
        width: 100%;
        padding: 0
    }
}

@media (min-width: 900px) {
    .field--body-paragraph-bottom .section-spacing.section-gray .container, .field--body-paragraph-top .section-spacing.section-gray .container {
        width: 90%;
        padding: 0
    }
}

@media (min-width: 900px) {
    .field--body-paragraph-bottom .section-spacing.section-gray .container .section-spacing .container, .field--body-paragraph-top .section-spacing.section-gray .container .section-spacing .container {
        width: 100%;
        padding: 0
    }
}

.field--body-paragraph-bottom div + div .section-spacing, .field--body-paragraph-top div + div .section-spacing {
    padding-top: 0
}

.l-1up, .l-1up-small-centered {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 900px) {
    .l-1up-small-centered {
        width: 65.71429%;
        float: left;
        margin-right: -100%;
        margin-left: 17.14286%;
        clear: both
    }
}

.l-2up {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-2up {
        clear: right
    }
}

@media (min-width: 600px) {
    .l-2up, .l-2up:nth-of-type(odd) {
        width: 48.57143%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-2up:nth-of-type(odd) {
        clear: right;
        clear: both
    }
}

@media (min-width: 600px) {
    .l-2up:nth-of-type(2n) {
        width: 48.57143%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.l-2up-eb {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 900px) {
    .l-2up-eb {
        clear: right
    }
}

@media (min-width: 900px) {
    .l-2up-eb, .l-2up-eb:nth-of-type(odd) {
        width: 48.57143%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-2up-eb:nth-of-type(odd) {
        clear: right;
        clear: both
    }
}

@media (min-width: 900px) {
    .l-2up-eb:nth-of-type(2n) {
        width: 48.57143%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.l-2up--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-2up--1 {
        width: 48.57143%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-2up--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-2up--2 {
        width: 48.57143%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-2up--1-eb {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 900px) {
    .l-2up--1-eb {
        width: 48.57143%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-2up--2-eb {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 900px) {
    .l-2up--2-eb {
        width: 48.57143%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-3up {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-3up {
        clear: right
    }
}

@media (min-width: 600px) {
    .l-3up, .l-3up:nth-of-type(3n+1) {
        width: 31.42857%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-3up:nth-of-type(3n+1) {
        clear: right;
        clear: both
    }
}

@media (min-width: 600px) {
    .l-3up:nth-of-type(3n) {
        width: 31.42857%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.l-3up--eb {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) and (max-width: 900px) {
    .l-3up--eb {
        clear: right
    }
}
@media (min-width: 1800px) {
    body.cels.explorer-page.user {background:none}
}

@media (min-width: 600px) and (max-width: 900px) {
    .l-3up--eb, .l-3up--eb:nth-of-type(odd) {
        width: 48.57143%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-3up--eb:nth-of-type(odd) {
        clear: right;
        clear: both
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .l-3up--eb:nth-of-type(2n) {
        width: 48.57143%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

@media (min-width: 900px) {
    .l-3up--eb {
        clear: right
    }
}

@media (min-width: 900px) {
    .l-3up--eb, .l-3up--eb:nth-of-type(3n+1) {
        width: 31.42857%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-3up--eb:nth-of-type(3n+1) {
        clear: right;
        clear: both
    }
}

@media (min-width: 900px) {
    .l-3up--eb:nth-of-type(3n) {
        width: 31.42857%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.l-3up--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-3up--1 {
        width: 31.42857%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-3up--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-3up--2 {
        width: 31.42857%;
        float: left;
        margin-right: -100%;
        margin-left: 34.28571%;
        clear: none
    }
}

.l-3up--3 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-3up--3 {
        width: 31.42857%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-4up {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 425px) and (max-width: 900px) {
    .l-4up {
        clear: right
    }
}

@media (min-width: 425px) and (max-width: 900px) {
    .l-4up, .l-4up:nth-of-type(odd) {
        width: 48.57143%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-4up:nth-of-type(odd) {
        clear: right;
        clear: both
    }
}

@media (min-width: 425px) and (max-width: 900px) {
    .l-4up:nth-of-type(2n) {
        width: 48.57143%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

@media (min-width: 900px) {
    .l-4up {
        clear: right
    }
}

@media (min-width: 900px) {
    .l-4up, .l-4up:nth-of-type(4n+1) {
        width: 22.85714%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-4up:nth-of-type(4n+1) {
        clear: right;
        clear: both
    }
}

@media (min-width: 900px) {
    .l-4up:nth-of-type(4n) {
        width: 22.85714%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.l-4up--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-4up--1 {
        width: 22.85714%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-4up--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-4up--2 {
        width: 22.85714%;
        float: left;
        margin-right: -100%;
        margin-left: 25.71429%;
        clear: none
    }
}

.l-4up--3 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-4up--3 {
        width: 22.85714%;
        float: left;
        margin-right: -100%;
        margin-left: 51.42857%;
        clear: none
    }
}

.l-4up--4 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-4up--4 {
        width: 22.85714%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-6up {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up {
        clear: right
    }
}

@media (min-width: 600px) {
    .l-6up, .l-6up:nth-of-type(6n+1) {
        width: 14.28571%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .l-6up:nth-of-type(6n+1) {
        clear: right;
        clear: both
    }
}

@media (min-width: 600px) {
    .l-6up:nth-of-type(6n) {
        width: 14.28571%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.l-6up--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up--1 {
        width: 14.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-6up--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up--2 {
        width: 14.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 17.14286%;
        clear: none
    }
}

.l-6up--3 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up--3 {
        width: 14.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 34.28571%;
        clear: none
    }
}

.l-6up--4 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up--4 {
        width: 14.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 51.42857%;
        clear: none
    }
}

.l-6up--5 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up--5 {
        width: 14.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 68.57143%;
        clear: none
    }
}

.l-6up--6 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-6up--6 {
        width: 14.28571%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-33-66--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-33-66--1 {
        width: 31.42857%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-33-66--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-33-66--2 {
        width: 65.71429%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-66-33--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-66-33--1 {
        width: 65.71429%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-66-33--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-66-33--2 {
        width: 31.42857%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-25-75--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-25-75--1 {
        width: 22.85714%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-25-75--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-25-75--2 {
        width: 74.28571%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-75-25--1 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-75-25--1 {
        width: 74.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-75-25--2 {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-75-25--2 {
        width: 22.85714%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none
    }
}

.l-main {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-main {
        width: 65.71429%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: left
    }
}

@media (min-width: 900px) {
    .l-main {
        width: 74.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: left
    }
}

@media (min-width: 1100px) {
    .l-main {
        width: 65.71429%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: left
    }
}

.l-main--no-rail {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-main--no-rail {
        width: 100%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: both
    }
}

@media (min-width: 900px) {
    .l-main--no-rail {
        width: 74.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: both
    }
}

.l-rail {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .l-rail {
        width: 31.42857%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: right
    }
}

@media (min-width: 900px) {
    .l-rail {
        width: 22.85714%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: right
    }
}

.hidden {
    display: none
}

.visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal
}

.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
    position: static !important;
    clip: auto;
    overflow: visible;
    height: auto;
    width: auto
}

.invisible {
    visibility: hidden
}

div#block-tabs {
    margin: 2em 0
}

div#block-tabs ul {
    border-bottom: 1px solid #d1d1d1;
    list-style: none;
    margin: 0 0 .5em;
    padding: 0
}

div#block-tabs ul > li {
    display: inline-block;
    margin-right: .3em
}

div#block-tabs a {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #a5a5a5;
    border: 1px solid transparent
}

div#block-tabs a.is-active {
    color: #333;
    border: 1px solid #d1d1d1;
    border-top: 3px solid #00609c;
    border-bottom: 1px solid #fff
}

div#block-tabs a:focus, div#block-tabs a:hover {
    color: #00609c;
    text-decoration: none
}

.slick-slider {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list, .slick-slider {
    position: relative;
    display: block
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
    transform: translateZ(0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block
}

.slick-track:after, .slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slick-next, .slick-prev {
    z-index: 5;
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background-color: transparent;
    color: transparent;
    top: 50%;
    padding: 0;
    border: none;
    outline: none;
    opacity: .5
}

.slick-next:active, .slick-next:focus, .slick-next:hover, .slick-prev:active, .slick-prev:focus, .slick-prev:hover {
    outline: none;
    background-color: transparent;
    color: transparent;
    opacity: 1
}

.slick-next.slick-disabled, .slick-prev.slick-disabled {
    opacity: 0
}

.featured-stories .slick-next, .featured-stories .slick-prev {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff
}

.slick-prev {
    background-image: url(/themes/custom/anl_parent_theme/imgs/arrow-left.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    left: -20px
}

[dir=rtl] .slick-prev {
    background-image: url(/themes/custom/anl_parent_theme/imgs/arrow-right.png);
    left: auto;
    right: -20px
}

.featured-stories .slick-prev {
    left: -25px;
    background-size: 20px
}

.slick-next {
    right: -20px;
    background-image: url(/themes/custom/anl_parent_theme/imgs/arrow-right.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%
}

[dir=rtl] .slick-next {
    background-image: url(/themes/custom/anl_parent_theme/imgs/arrow-left.png);
    left: -20px;
    right: auto
}

.featured-stories .slick-next {
    right: -25px;
    background-size: 20px
}

.slick-dots {
    list-style: none;
    height: 10px;
    cursor: pointer
}

.slick-dots, .slick-dots li {
    position: relative;
    padding: 0
}

.slick-dots li {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px
}

.slick-dots li.slick-active button {
    background-color: #00609c
}

.slick-dots li button:active, .slick-dots li button:hover {
    background-color: #004169;
    transform: translateY(-3px)
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    border: 0;
    transition: all .5s;
    background-color: rgba(0, 96, 156, .5)
}

.node-type--public .slick-dots {
    margin-bottom: 1.5em
}


.button--gray:link, .button--gray:visited, .button--reset:link, .button--reset:visited, .button:link, .button:visited, button:link, button:visited, button[type=submit]:link, button[type=submit]:visited, input.button--reset:link[type=submit], input.button--reset:visited[type=submit], input:link[type=reset], input:visited[type=reset], input[type=button]:link, input[type=button]:visited, input[type=reset]:link, input[type=reset]:visited, input[type=submit]:link, input[type=submit]:visited {
    color: #fff
}

/*.button--gray:active, .button--gray:focus, .button--gray:hover, .button--reset:active, .button--reset:focus, .button--reset:hover, .button:active, .button:focus, .button:hover, button:active, button:focus, button:hover, button[type=submit]:active, button[type=submit]:focus, button[type=submit]:hover, input.button--reset:active[type=submit], input.button--reset:focus[type=submit], input.button--reset:hover[type=submit], input:active[type=reset], input:focus[type=reset], input:hover[type=reset], input[type=button]:active, input[type=button]:focus, input[type=button]:hover, input[type=reset]:active, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:active, input[type=submit]:focus, input[type=submit]:hover {

    color: #fff;
    text-decoration: none;
    outline: none
}*/

.button--gray .fa, .button--reset .fa, .button .fa, button .fa, button[type=submit] .fa, input.button--reset[type=submit] .fa, input[type=button] .fa, input[type=reset] .fa, input[type=submit] .fa {
    margin-left: .375em;
    font-size: 90%
}

.button--full-width, .button--full-width[type=button], .button--full-width[type=reset], .button--full-width[type=submit] {
    width: 100%
}

.button--gray, .button--reset, input.button--reset[type=submit], input[type=reset] {
    color: #666;
    background-color: #f2f2f2;
    border: 1px solid #a5a5a5
}

.button--gray:link, .button--gray:visited, .button--reset:link, .button--reset:visited, input.button--reset:link[type=submit], input.button--reset:visited[type=submit], input[type=reset]:link, input[type=reset]:visited {
    color: #666
}

.button--gray:active, .button--gray:focus, .button--gray:hover, .button--reset:active, .button--reset:focus, .button--reset:hover, input.button--reset:active[type=submit], input.button--reset:focus[type=submit], input.button--reset:hover[type=submit], input[type=reset]:active, input[type=reset]:focus, input[type=reset]:hover {
    color: #fff;
    background-color: #666
}

form {
    margin-bottom: 3em
}

.button--container {
    margin: 24px 0
}

.input-wrapper, .input-wrapper--half {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 24px
}

@media (min-width: 600px) {
    .input-wrapper--half {
        width: 50%;
        float: left
    }

    .input-wrapper--half:first-child {
        padding-right: 12px
    }

    .input-wrapper--half:last-child {
        padding-left: 12px
    }
}

.input-wrapper--33 {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 24px
}

@media (min-width: 600px) and (max-width: 900px) {
    .input-wrapper--33:nth-of-type(odd) {
        width: 48.57143%;
        clear: right;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%;
        clear: both
    }

    .input-wrapper--33:nth-of-type(2n) {
        width: 48.57143%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

@media (min-width: 900px) {
    .input-wrapper--33 {
        clear: right
    }

    .input-wrapper--33, .input-wrapper--33:nth-of-type(3n+1) {
        width: 31.42857%;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%
    }

    .input-wrapper--33:nth-of-type(3n+1) {
        clear: right;
        clear: both
    }

    .input-wrapper--33:nth-of-type(3n) {
        width: 31.42857%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

label, legend {
    font-size: .85em;
    /*line-height: 1.76471em;*/
    /*color: #666;*/
    margin-bottom: .375em
}

::-webkit-input-placeholder {
    color: #666
}

:-moz-placeholder, ::-moz-placeholder {
    color: #666
}

:-ms-input-placeholder {
    color: #666
}

input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=range], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week] {
    outline: 0;
    border: 0;
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    color: #000;
    background-color: transparent;
    padding: 0px;
    border-radius : 0;
    width:100%

}

/*input[type=date]:active, input[type=date]:focus, input[type=date]:hover, input[type=datetime-local]:active, input[type=datetime-local]:focus, input[type=datetime-local]:hover, input[type=datetime]:active, input[type=datetime]:focus, input[type=datetime]:hover, input[type=email]:active, input[type=email]:focus, input[type=email]:hover, input[type=month]:active, input[type=month]:focus, input[type=month]:hover, input[type=number]:active, input[type=number]:focus, input[type=number]:hover, input[type=password]:active, input[type=password]:focus, input[type=password]:hover, input[type=range]:active, input[type=range]:focus, input[type=range]:hover, input[type=search]:active, input[type=search]:focus, input[type=search]:hover, input[type=tel]:active, input[type=tel]:focus, input[type=tel]:hover, input[type=text]:active, input[type=text]:focus, input[type=text]:hover, input[type=time]:active, input[type=time]:focus, input[type=time]:hover, input[type=url]:active, input[type=url]:focus, input[type=url]:hover, input[type=week]:active, input[type=week]:focus, input[type=week]:hover {
    border-color: #666
}*/

input[type=color] {
    display: block;
    width: 100%
}

select {
    width: 100%;
    outline: 0;
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    color: #000;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    background: #fff;
    background-position: 98% 50%;
    background-repeat: no-repeat;
    padding: 0px
}

select:active, select:focus, select:hover,
input:active, input:focus, input:hover,
.cels textarea:active, .cels textarea:focus, .cels textarea:hover{
    border-color: #666
}

input[type=checkbox], input[type=radio] {
    display: inline-block
}

/*input[type=checkbox] + label, input[type=radio] + label {
    font-size: 1em;
    line-height: 1.5em;
    position: relative;
    top: 2px;
    display: inline-block;
    margin-bottom: 0;
    color: #333;
    text-transform: none;
    margin: 0 1.875em 0 .375em
}*/

.cels textarea {
    padding: 12px;
    width: 100%;
    outline: 0;
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    color: #000;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: 12px 8px;
}

fieldset {
    margin: 24px 0;
    padding: 0
}

.chosen-container {
    font-size: 1em;
    line-height: 1.5em;
    font-family: proxima-nova, Helvetica, Arial, Nimbus Sans L, sans-serif
}

.chosen-container .chosen-drop {
    font-size: .85em;
    line-height: 1.76471em;
    border: 1px solid #d1d1d1;
    background: #fff;
    box-shadow: none
}

.chosen-container .chosen-single .group-name, .chosen-container .search-choice .group-name {
    color: #333
}

.chosen-container .chosen-results li.highlighted {
    background-color: #00609c;
    background-image: none;
    color: #fff
}

.chosen-container .chosen-results li.no-results {
    color: #333;
    display: list-item;
    background: #off-white
}

.chosen-container-single .chosen-single {
    padding: 12px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 0;
    background-color: #fff;
    background: #fff;
    box-shadow: none;
    color: #333;
    line-height: 24px;
    height: 55px
}

.chosen-container-single .chosen-single:active, .chosen-container-single .chosen-single:focus, .chosen-container-single .chosen-single:hover {
    border-color: #333
}

.chosen-container-single .chosen-single div b {
    background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 98% 50%;
    background-repeat: no-repeat
}

.chosen-container-single .chosen-default {
    color: #333
}

.chosen-container-single .chosen-search input[type=text] {
    border: 1px solid #f2f2f2;
    font-family: proxima-nova, Helvetica, Arial, Nimbus Sans L, sans-serif
}

.chosen-container-active .chosen-single {
    border: 1px solid #333;
    box-shadow: none
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #333;
    background-image: none;
    box-shadow: none
}

.input-wrapper--small {
    font-size: .7em;
    line-height: 1.39286em;
    margin-bottom: .375em
}

.input-wrapper--small input[type=date], .input-wrapper--small input[type=datetime-local], .input-wrapper--small input[type=datetime], .input-wrapper--small input[type=email], .input-wrapper--small input[type=month], .input-wrapper--small input[type=number], .input-wrapper--small input[type=range], .input-wrapper--small input[type=search], .input-wrapper--small input[type=tel], .input-wrapper--small input[type=text], .input-wrapper--small input[type=time], .input-wrapper--small input[type=url], .input-wrapper--small input[type=week], .input-wrapper--small select {
    padding: 6px 4px
}

.input-wrapper--small .chosen-container-single .chosen-single {
    padding: 3px 4px;
    margin-top: 0;
    height: 32px
}

.input-wrapper--small .button--container {
    margin-top: 20px;
    margin-bottom: .375em
}

form.user-login-form, form.user-pass {
    background: #f2f2f2;
    padding: 3em
}

form.user-login-form input[type=password], form.user-login-form input[type=text], form.user-pass input[type=password], form.user-pass input[type=text] {
    background: #fff
}

form.user-login-form #edit-pass, form.user-login-form #edit-submit, form.user-pass #edit-pass, form.user-pass #edit-submit {
    margin-top: 1.5em
}

form.user-login-form p:last-of-type, form.user-pass p:last-of-type {
    margin-bottom: 0
}

form.user-login-form .login-form-actions, form.user-pass .login-form-actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

form.user-login-form .login-form-actions #edit-actions, form.user-pass .login-form-actions #edit-actions {
    margin-right: 1.5em
}

form.user-login-form .login-form-actions .simplesamlphp-auth-login-link, form.user-pass .login-form-actions .simplesamlphp-auth-login-link {
    color: #666;
    margin-top: 1.5em
}

label[for=mce-EMAIL] {
    display: block
}

#mce-EMAIL {
    display: inline-block;
    margin-bottom: .75em;
    margin-right: .75em;
    width: 100%
}

@media (min-width: 600px) {
    #mce-EMAIL {
        width: calc(100% - 150px)
    }
}

#mce-EMAIL + .button, #mce-EMAIL + .s_button {
    padding: 12px 10px
}

.intl-tel-input {
    display: block !important
}

.page-wrapper {
    margin-top: 2.25em;
    margin-bottom: 6em
}

@media (min-width: 0) and (max-width: 600px) {
    .page-wrapper main {
        margin-bottom: 4.5em
    }
}

.field--flexible-content-bottom {
    margin-bottom: 6em
}

.page-wrapper + div .field--flexible-content-bottom {
    margin-top: -6em
}

.page-wrapper--org {
    margin-top: 2.25em;
    margin-bottom: 1.5em
}

@media (min-width: 0) and (max-width: 600px) {
    .page-wrapper--org main {
        margin-bottom: 2.25em
    }
}

.divider {
    display: inline-block;
    margin: 0 .525em
}

.intro {
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    border-bottom: 1px solid #f2f2f2
}

.lede {
    /*font-size: 1.26em;*/
    line-height: 1.30952em;
    color: #666
}

.secondary__title {
    font-size: 1em;
    line-height: 1.5em;
    text-transform: uppercase
}

@media (min-width: 600px) {
    .l-2up-block {
        width: 50%
    }
}

@media (min-width: 600px) {
    .l-2up-block:nth-of-type(odd) {
        padding-right: .75em;
        float: left;
        clear: left
    }
}

@media (min-width: 600px) {
    .l-2up-block:nth-of-type(2n) {
        padding-left: .75em;
        float: right
    }
}

.tags-footer {
    margin-top: 2.25em;
    padding-top: 1.5em;
    border-top: 1px solid #d1d1d1
}

.tags-footer__label {
    color: #333;
    font-weight: 700;
    margin-right: .375em
}

.tags {
    font-size: .75em;
    line-height: 1.3em;
    margin-top: .89286em;
    text-transform: capitalize
}

.tags a:link, .tags a:visited {
    color: #666
}

.tags a:active, .tags a:focus, .tags a:hover {
    color: #333;
    text-decoration: underline
}

.header {
    background: #fff;
    border-top: 3px solid #666;
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 0
}

@media (min-width: 900px) {
    .header {
        padding-top: 35px;
        padding-bottom: 0
    }
}

@media (min-width: 1100px) {
    .header {
        padding-top: 38px
    }
}



@media (min-width: 0) and (max-width: 600px) {
    .header-container {
        position: relative
    }
}

.header__brand {
    display: inline-block;
    /*width: 60%*/
}

.header__brand .logo {
    font-size: 1em;
    line-height: 1.5em;
    margin: 9px 0
}

.header__brand .logo svg {
    transition: all .2s ease-in-out;
    max-width: 100%;
    height: 46px;
    width: 160px
}

.header__brand .logo a {
    display: block;
    height: 46px;
    overflow: hidden
}

@media (min-width: 900px) {
    .header__brand {
        width: 200px
    }
}

@media (min-width: 900px) {
    .header__right {
        position: absolute;
        left: 70%;
        top: 0
    }
}

@media (min-width: 1100px) {
    .header__right {
        left: 75%
    }
}

.nav-trigger {
    position: absolute;
    left: .5em;
    height: 36px;
    width: 40px;
    border: 1px solid #f2f2f2;
    background-color: transparent
}



.nav-trigger:active, .nav-trigger:focus, .nav-trigger:hover {
    background-color: #f2f2f2
}

.nav-trigger:active svg, .nav-trigger:focus svg, .nav-trigger:hover svg {
    fill: #00609c
}

@media (min-width: 0) and (max-width: 600px) {
    .nav-trigger {
        top: 1.5em
    }
}

@media (min-width: 600px) {
    .nav-trigger {
        top: 1.875em
    }
}

@media (min-width: 900px) {
    .nav-trigger {
        display: none
    }
}

@media (min-width: 0) and (max-width: 900px) {
    .nav-drawer {
        display: none;
        visibility: hidden;
        position: absolute;
        top: 74px;
        right: -1px;
        left: -1px;
        background-color: #fff;
        border: 1px solid #d1d1d1
    }

    .nav-drawer.is-active {
        display: block;
        visibility: visible
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .nav-drawer {
        top: 84px
    }
}

@media (min-width: 900px) {
    .nav-drawer {
        float: right
    }
}

.nav-list__item {
    transition: none 0 ease;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid #d1d1d1;
    padding: 0 5.25em 0 1.5em;
    text-align: left
}

.nav-list__item a:link, .nav-list__item a:visited {
    color: #333;
    display: block;
    padding: .525em 0;
}

.nav-list__item a:active, .nav-list__item a:focus, .nav-list__item a:hover {
    text-decoration: none;
    color: #00609c !important;
}

@media (min-width: 768px) {


    .header.sticky {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 6
    }
    .nav-list__item {
        font-size: 1em;
        line-height: 1.58824em;
        padding: 0 .375em;
        border-bottom: 0;
        display: inline-block
    }

    .nav-list__item a:link, .nav-list__item a:visited {
        color: #333;
        display: block;
        padding: 18px .375em;
        /*border-top: 3px solid #666*/
    }

    .nav-list__item a:active, .nav-list__item a:focus, .nav-list__item a:hover {
        color: #333
    }

    .nav-list__item.is-active > .nav-sublist, .nav-list__item:hover > .nav-sublist {
        display: block;
        visibility: visible
    }
}

.nav-sublist-trigger {
    border: 0;
    position: absolute;
    right: 1.5em;
    top: 0;
    padding: 10px 15px 9px 14px;
    background-color: transparent
}

.nav-sublist-trigger svg {
    height: 13px;
    width: 10px;
    fill: #00609c
}

.nav-sublist-trigger:active, .nav-sublist-trigger:focus, .nav-sublist-trigger:hover {
    background-color: #f2f2f2
}

.nav-list__item.is-active .nav-sublist-trigger {
    transition: all .3s ease
}

.nav-list__item.is-active .nav-sublist-trigger svg {
    transform: rotate(90deg)
}

@media (min-width: 768px) {
    .nav-sublist-trigger {
        display: none;
        visibility: hidden
    }
}

.nav-sublist {
    display: none;
    visibility: hidden;
    border-top: 1px solid #e0e0e0
}

.nav-list__item.is-active .nav-sublist {
    display: block;
    visibility: visible
}

@media (min-width: 768px) {
    .nav-sublist {
        background-color: #f2f2f2;
        position: absolute;
        right: 0;
        width: auto;
        min-width:190px;
        z-index: 4
    }

    .nav-sublist:before {
        position: absolute;
        top: -15px;
        right: 0;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-right: 30px solid #f2f2f2;
        z-index: 0
    }
}

.nav-sublist__item {
    line-height: 1.275em;
    text-transform: none;
    position: relative
}

.nav-sublist__item a:active, .nav-sublist__item a:focus, .nav-sublist__item a:hover {
    border-color: transparent;
    text-decoration: underline
}

@media (min-width: 768px) {
    .nav-sublist__item a:link, .nav-sublist__item a:visited {
        border: 0;
        padding: .375em .75em;
        border-bottom: 1px solid #fff
    }
}

.utility-nav {
    padding-bottom: .375em
}

@media (min-width: 900px) {
    .utility-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0
    }
}

@media (min-width: 900px) {
    .utility-nav-container {
        margin: 0 auto;
        width: 90%
    }
}

@media (min-width: 1100px) {
    .utility-nav-container {
        max-width: 1250px
    }
}

.utility-nav__list-item {
    font-size: .85em;
    line-height: 1.58824em;
    text-transform: uppercase;
    font-weight: 700;
    float: left
}

.utility-nav__list-item a:link, .utility-nav__list-item a:visited {
    display: block;
    padding: .675em 1.5em 0
}

.utility-nav__list-item a:active, .utility-nav__list-item a:focus, .utility-nav__list-item a:hover {
    color: #fff
}

/*@media (min-width: 900px) {
    .utility-nav__list-item {
        font-size: .7em;
        line-height: 1.71429em
    }

    .utility-nav__list-item a:link, .utility-nav__list-item a:visited {
        padding: .525em 3em 0 0
    }
}*/

.site-search label {
    color: #d1d1d1
}

.site-search input[type=search], .site-search select {
    color: #fff
}

.site-search .input-wrapper {
    margin-bottom: .75em
}

.search-trigger {
    position: absolute;
    right: 4.5em;
    height: 36px;
    width: 40px;
    border: 1px solid #f2f2f2;
    background-color: transparent
}

.search-trigger svg {
    fill: #333;
    height: 18px;
    width: 18px
}

.search-trigger:active, .search-trigger:focus, .search-trigger:hover {
    background-color: #f2f2f2;
    color: #00609c
}

.search-trigger:active svg, .search-trigger:focus svg, .search-trigger:hover svg {
    fill: #00609c
}

@media (min-width: 0) and (max-width: 600px) {
    .search-trigger {
        top: 1.5em
    }
}

@media (min-width: 600px) {
    .search-trigger {
        top: 1.875em
    }
}

@media (min-width: 900px) {
    .search-trigger {
        top: 0;
        height: 30px;
        margin-top: 3px;
        background-color: transparent;
        padding: 6px;
        border: 0
    }

    .search-trigger svg {
        fill: #d1d1d1
    }

    .search-trigger:active, .search-trigger:focus, .search-trigger:hover {
        background-color: transparent
    }

    .search-trigger:active svg, .search-trigger:focus svg, .search-trigger:hover svg {
        fill: #fff
    }
}

@media (min-width: 900px) {
    .site-search-container {
        padding: 1.5em 0;
        margin: 0 auto;
        width: 90%
    }
}

@media (min-width: 1100px) {
    .site-search-container {
        max-width: 1400px
    }
}

.site-search__form {
    display: none;
    visibility: hidden;
    position: absolute;
    z-index: 5
}

@media (min-width: 0) and (max-width: 900px) {
    .site-search__form {
        top: 74px;
        right: -1px;
        left: -1px;
        padding: 1.5em
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .site-search__form {
        top: 84px
    }
}

@media (min-width: 900px) {
    .site-search__form {
        top: 34px;
        right: 0;
        left: 0;
        border-top: 1px solid #000
    }
}

@media (min-width: 1100px) {
    .site-search__form {
        top: 37px
    }
}

.site-search.is-active .site-search__form {
    display: block;
    visibility: visible
}

@media (min-width: 900px) {
    .site-search__input {
        width: 60%;
        display: inline-block
    }
}

.site-search__input[type=search] {
    color: #fff
}

.site-search__context {
    width: 80%;
    width: calc(100% - 45px);
    display: inline-block
}

.site-search__context select {
    background: #333 url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#fff' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 98% 50%;
    background-repeat: no-repeat
}

@media (min-width: 0) and (max-width: 900px) {
    .site-search__context select {
        padding: 8px
    }
}

@media (min-width: 900px) {
    .site-search__context {
        width: 33%;
        width: calc(40% - 50px)
    }
}

.search-submit[type=submit] {
    background-image: url(/themes/custom/anl_parent_theme/imgs/svg/search.svg);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 50%;
    color: transparent;
    background-color: transparent;
    border: 0;
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-top: 23px;
    display: inline-block;
    padding: 19px 10px;
    float: right;
    opacity: .75 . search-submit [type = "submit" ]
}

.search-submit[type=submit] :active, .search-submit[type=submit]:focus, .search-submit[type=submit]:hover {
    border: 0;
    opacity: 1;
    color: transparent;
    background-color: #00609c
}

@media (min-width: 900px) {
    .search-submit[type=submit] {
        height: 55px;
        margin-top: 27px
    }
}

.section-search {
    border: 1px solid #d1d1d1;
    padding: 1.5em;
    margin-bottom: 1.5em
}

.section-search__title {
    font-size: .85em;
    line-height: 1.76471em;
    margin-top: 0;
    text-transform: uppercase
}

.section-search__input {
    width: 70%;
    width: calc(100% - 100px);
    float: left;
    margin-bottom: 0
}

@media (min-width: 600px) {
    .section-search__input {
        width: 80%
    }
}

@media (min-width: 900px) {
    .section-search__input {
        width: 85%
    }
}

.section-search__input[type=search] {
    background-color: hsla(0, 0%, 100%, .7)
}

.section-search-submit[type=submit] {
    margin-top: 27px;
    padding: 14px 10px;
    float: right
}

@media (min-width: 900px) {
    .section-search-submit[type=submit] {
        margin-top: 30px
    }
}

#gsa_insideout_search_title_header a[ctype=advanced] {
    display: none
}

@media (min-width: 900px) {
    #gsa_insideout_search_title_header a[ctype=advanced] {
        display: inline
    }
}

.rail {
    margin-bottom: 1.5em
}

.rail-block {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    border-top: 3px solid #0082ca;
    padding: .675em .75em .75em;
    margin-bottom: .75em
}

@media (min-width: 600px) {
    .rail-block .button {
        width: 100%
    }
}

.rail-paragraph .rail-block {
    border-top: none
}

.rail-title, .rail .key-info__title {
    font-size: .7em;
    line-height: 1.39286em;
    margin-top: 0;
    margin-bottom: .225em;
    text-transform: uppercase;
    color: #333
}

.rail-text {
    font-size: .85em;
    line-height: 1.32353em;
    margin: .75em 0
}

.rail-list li {
    border-bottom: 1px solid #f2f2f2
}

.rail-list li:last-child {
    border-bottom: 0
}

/*.rail-nav {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2
}*/

.rail-nav__trigger {
    width: 100%;
    display: block;
    text-align: center
}

.rail-nav__trigger .fa {
    margin-right: .75em
}

@media (min-width: 600px) {
    .rail-nav__trigger {
        display: none;
        visibility: hidden
    }
}

.rail-nav-list {
    display: block;
    visibility: visible
}

.rail-nav-list__item {
    font-size: .85em;
    line-height: 1.23529em;
    position: relative
}

.rail-nav-list__item > a:link, .rail-nav-list__item > a:visited {
    border-bottom: 1px solid #fff;
    display: block;
    font-weight: 700;
    color: #333;
    padding: .675em 50px .675em .675em
}

.rail-nav-list__item.is-active .rail-nav-sublist-trigger {
    top: 0;
    transform: rotate(90deg)
}

.rail-nav-sublist-trigger {
    position: absolute;
    top: 1px;
    right: 8px;
    background-color: transparent;
    border: 0;
    color: #00609c;
    padding: 10px;
    margin-left: 0
}

.rail-nav-sublist-trigger:active, .rail-nav-sublist-trigger:focus, .rail-nav-sublist-trigger:hover {
    background-color: transparent;
    transform: rotate(90deg);
    color: #00609c
}

.rail-nav-sublist-trigger:focus {
    border: 1px solid #00609c
}

.rail-nav-sublist {
    transition: .5s ease .3s;
    border-top: 3px solid #00609c;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    visibility: hidden
}

.rail-nav-list__item.is-active .rail-nav-sublist {
    transition: .5s ease .3s;
    max-height: 999px;
    visibility: visible;
    padding: .375em 0
}

.rail-nav-sublist__item > a:link, .rail-nav-sublist__item > a:visited {
    display: block;
    padding: .375em .675em
}

.rail-nav-sublist__item.is-active a:link, .rail-nav-sublist__item.is-active a:visited {
    color: #0082ca;
    font-weight: 600
}

.footer {
    font-size: .85em;
    line-height: 1.32353em;
    /*padding-top: 1.5em;*/
    /*
    border-top: 3px solid #a5a5a5
    */
}

.footer .section-spacing {
    padding: .75em 0
}

@media (min-width: 600px) {
    .footer .section-spacing {
        padding: .75em 0
    }
}

.footer-address {
    color: #666;
    margin-bottom: 1.5em
}

@media (min-width: 900px) {
    .footer-address {
        margin-bottom: 0
    }
}

.footer-address a:link, .footer-address a:visited {
    color: #666
}

.footer-address a:active, .footer-address a:focus, .footer-address a:hover {
    color: #333
}

.footer-address__title {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 700;
    margin: 0
}

.footer-address__list {
    margin-bottom: .375em
}

.footer-address__list li {
    line-height: 1.425em;
    margin-bottom: 0
}

.footer .social-media {
    padding-top: 0;
    text-align: left;
    margin-top: 1.5em
}

.footer .social-media__list {
    margin-bottom: .75em;
    width: 100%
}

@media (min-width: 900px) {
    .footer .social-media__list {
        width: 160px
    }
}

@media (min-width: 1100px) {
    .footer .social-media__list {
        width: 100%
    }
}

.footer .social-media__list li {
    display: block;
    float: left;
    margin-left: 0;
    margin-right: 6px;
    width: 32px
}

@media (min-width: 1100px) {
    .footer .social-media__list li {
        width: calc(14.28571% - 6px);
        max-width: 32px
    }
}

.footer .social-media__list li:last-child {
    margin-right: 0
}

.footer .social-media__list li svg {
    height: auto;
    width: 100%
}

.footer-nav-list {
    margin: 1.125em 0
}

.footer-nav-list__item {
    display: block;
    font-size: .85em;
    line-height: 1.5em;
    margin-bottom: .75em
}

.footer-nav-list__item a:link, .footer-nav-list__item a:visited {
    color: #666
}

.footer-nav-list__item a:active, .footer-nav-list__item a:focus, .footer-nav-list__item a:hover {
    color: #333
}

.footer-nav-list__label {
    line-height: 1.125em;
    padding-bottom: .225em;
    border-bottom: 1px solid #d1d1d1;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: #666
}

.footer-nav-sublist {
    margin: .75em 0 1.125em
}

.footer-nav-sublist__item {
    line-height: 1.125em;
    margin-bottom: .75em
}

@media (min-width: 900px) {
    .footer-nav-sublist__item {
        margin-bottom: .675em
    }
}

.footer-newsletter {
    margin-bottom: 2.25em;
    text-align: left
}

@media (min-width: 600px) {
    .footer-newsletter {
        margin-bottom: .75em
    }
}

.footer-newsletter label {
    font-size: .85em;
    line-height: 1.32353em;
    color: #666;
    display: block;
    font-weight: 700;
    text-transform: uppercase
}

.footer-newsletter input[type=email] {
    display: inline-block;
    margin: 0 .375em .75em 0;
    width: 100%
}

@media (min-width: 600px) {
    .footer-newsletter input[type=email] {
        padding: .375em;
        width: 80%;
        width: calc(100% - 125px)
    }
}

@media (min-width: 900px) {
    .footer-newsletter input[type=email] {
        width: 72%;
        width: calc(100% - 125px)
    }
}

@media (min-width: 1100px) {
    .footer-newsletter input[type=email] {
        width: 75%;
        width: calc(100% - 125px)
    }
}

.footer-newsletter .button {
    width: 100%
}

@media (min-width: 600px) {
    .footer-newsletter .button {
        width: auto
    }
}

.footer-newsletter div.clear {
    display: inline-block
}

@media (min-width: 600px) {
    .footer-newsletter div.clear {
        float: right
    }
}

.footer__bottom {
    text-align: center;
    background-color: #f2f2f2
}

.footer__bottom.section-spacing {
    padding: 1.5em 0
}

@media (min-width: 600px) {
    .footer__bottom {
        text-align: left
    }

    .footer__bottom.section-spacing {
        padding: 1.125em 0
    }
}

.footer__bottom .l-1up {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 600px) {
    .footer__bottom .l-1up {
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.footer__bottom .doe-logo-menu-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 600px) {
    .footer__bottom .doe-logo-menu-wrapper {
        -ms-flex-direction: row;
        flex-direction: row
    }
}

.footer__bottom .footer-nav-list {
    display: inline-block;
    vertical-align: middle;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 20px
}

.footer__bottom .footer-nav-list li {
    display: block
}

@media (min-width: 1100px) {
    .footer__bottom .footer-nav-list li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.875em
    }

    .footer__bottom .footer-nav-list li:last-child {
        margin-right: 0
    }
}

.footer-doe-logo {
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: -1px;
    width: 100%
}

.footer-doe-logo img {
    display: inline-block;
    height: 36px
}

@media (min-width: 600px) {
    .footer-doe-logo {
        margin-bottom: .375em;
        margin-right: 1.875em;
        width: auto
    }

    .footer-doe-logo img {
        display: block
    }
}

.footer-uchicago {
    font-size: .85em;
    line-height: 1.32353em;
    margin-top: 0;
    margin-bottom: 20px
}

@media (min-width: 600px) {
    .footer-uchicago {
        margin-right: 1.875em;
        margin-top: 0
    }
}

.footer-uchicago svg {
    display: block;
    height: 36px;
    margin-top: 0;
    max-width: 100%
}

@media (min-width: 600px) {
    .footer-uchicago svg {
        margin-top: 12px
    }
}

.hero-cta {
    background-image: url(/themes/custom/anl_parent_theme/imgs/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%
}

.hero-cta-background {
    background-color: rgba(51, 51, 51, .7);
    min-height: 250px;
    padding: 50px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 900px) {
    .hero-cta-background {
        min-height: 500px
    }
}

.hero-cta-background .l-1up-small-centered {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

.hero-cta-block {
    color: #fff;
    text-align: center
}

.l-2up--1 > .hero-cta-block, .l-2up--2 > .hero-cta-block {
    text-align: left
}

.hero-cta-block__title {
    text-transform: uppercase;
    font-weight: 400
}

.hero-cta-block__title span {
    font-weight: 700
}

@media (min-width: 900px) {
    .hero-cta-block__title {
        font-size: 3.2em;
        line-height: 1.03125em
    }
}

.hero-cta--xl .hero-cta-background {
    min-height: 400px;
    height: calc(100vh - 100px)
}

@media (min-width: 900px) {
    .hero-cta--xl .hero-cta-background {
        min-height: 500px;
        height: calc(85vh - 120px)
    }
}

@media (min-width: 1100px) {
    .hero-cta--xl .hero-cta-block__title {
        font-size: 4.5em;
        line-height: 1.08333em
    }
}

.hero-cta--small .hero-cta-background {
    min-height: 150px;
    padding: 30px 0
}

@media (min-width: 900px) {
    .hero-cta--small .hero-cta-background {
        min-height: 400px
    }
}

.hero-cta--ambient-video {
    position: relative;
    min-height: 250px
}

@media (min-width: 900px) {
    .hero-cta--ambient-video {
        min-height: 500px
    }
}

.hero-cta--ambient-video .hero-cta-background {
    position: relative;
    z-index: 2;
    height: 430px
}

@media (min-width: 600px) {
    .hero-cta--ambient-video .hero-cta-background {
        height: 494px
    }
}

@media (min-width: 700px) {
    .hero-cta--ambient-video .hero-cta-background {
        height: 500px
    }
}

@media (min-width: 1100px) {
    .hero-cta--ambient-video .hero-cta-background {
        height: 612px
    }
}

@media (min-width: 1400px) {
    .hero-cta--ambient-video .hero-cta-background {
        height: 700px
    }
}

.ambient-video--container, .ambient-video-vimeo {
    display: none
}

@media (min-width: 700px) {
    .ambient-video--container, .ambient-video-vimeo {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        overflow: hidden;
        height: 500px;
        z-index: 1
    }
}

@media (min-width: 1100px) {
    .ambient-video--container, .ambient-video-vimeo {
        height: 612px
    }
}

@media (min-width: 1400px) {
    .ambient-video--container, .ambient-video-vimeo {
        height: 700px
    }
}

.ambient-video--container iframe, .ambient-video-vimeo iframe {
    position: relative;
    width: 150%;
    height: 150%;
    top: -123px;
    left: -25%
}

@media (min-width: 1100px) {
    .ambient-video--container iframe, .ambient-video-vimeo iframe {
        width: 125%;
        height: 125%;
        top: -77px;
        left: -12.5%
    }
}

@media (min-width: 1400px) {
    .ambient-video--container iframe, .ambient-video-vimeo iframe {
        width: 200%;
        height: 200%;
        top: -350px;
        left: -50%
    }
}

.hero-cta__video {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%
}

.hero-cta__video-controls {
    display: none;
    visibility: hidden
}

@media (min-width: 700px) {
    .hero-cta__video-controls {
        display: block;
        visibility: visible;
        position: absolute;
        bottom: 20px;
        right: 20px
    }
}

.hero-cta__video-control {
    display: none;
    visibility: hidden
}

.hero-cta__video-control.is-active {
    display: block;
    visibility: visible
}

.hero-cta__video-control .fa {
    margin: 0
}

.ambient-video--html5 {
    background-color: #000;
    background-position: 50%;
    background-size: contain;
    bottom: 0;
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

.cta-link, .cta-link--light, .cta-link--small, .cta-link--white {
    font-size: .85em;
    line-height: 1.41176em;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase
}

.cta-link--light svg, .cta-link--small svg, .cta-link--white svg, .cta-link svg {
    width: 9px;
    height: 10px;
    fill: #333;
    margin-left: .375em
}

.cta-link--light:link, .cta-link--light:visited, .cta-link--small:link, .cta-link--small:visited, .cta-link--white:link, .cta-link--white:visited, .cta-link:link, .cta-link:visited {
    color: #333
}

.cta-link--light:active, .cta-link--light:focus, .cta-link--light:hover, .cta-link--small:active, .cta-link--small:focus, .cta-link--small:hover, .cta-link--white:active, .cta-link--white:focus, .cta-link--white:hover, .cta-link:active, .cta-link:focus, .cta-link:hover {
    color: #333;
    text-decoration: underline
}

@media (min-width: 900px) {
    .cta-link--light svg, .cta-link--small svg, .cta-link--white svg, .cta-link svg {
        width: 16px;
        height: 12px
    }
}

.cta-link--light svg {
    fill: #666
}

.cta-link--light:link, .cta-link--light:visited {
    color: #666
}

.cta-link--light:active, .cta-link--light:focus, .cta-link--light:hover {
    color: #666;
    text-decoration: underline
}

.cta-link--white svg {
    fill: #fff
}

.cta-link--white:link, .cta-link--white:visited {
    color: #fff
}

.cta-link--white:active, .cta-link--white:focus, .cta-link--white:hover {
    color: #fff;
    text-decoration: underline
}

.cta-link--right {
    float: right;
    margin-left: .75em;
    margin-bottom: 1.5em
}

.cta-link--small {
    font-size: .7em;
    line-height: 1.5em
}

.cta-link--small svg {
    width: 12px;
    height: 9px;
    margin-left: .15em
}

.teaser-header {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: .66879em;
    text-transform: uppercase
}

.teaser {
    margin-bottom: 1.5em
}

.teaser__block {
    border-top: 3px solid #a5a5a5;
    padding-top: .15em
}

.teaser__label {
    font-size: .7em;
    line-height: 1.71429em;
    font-weight: 600;
    text-transform: uppercase;
    color: #333
}

.teaser__label:link, .teaser__label:visited {
    color: #333
}

.teaser__label:active, .teaser__label:focus, .teaser__label:hover {
    color: #333;
    text-decoration: underline
}

.teaser__image {
    width: 100%;
    display: block
}

.teaser__title {
    font-size: 1.26em;
    line-height: 1.0119em;
    margin-top: .59524em;
    margin-bottom: 0
}

.teaser__desc {
    font-size: .85em;
    line-height: 1.32353em;
    margin-top: .59524em
}

.teaser__meta {
    font-size: .7em;
    line-height: 1.39286em;
    margin-top: .59524em;
    color: #666
}

.teaser__meta span {
    display: inline-block
}

.teaser__meta a:link, .teaser__meta a:visited {
    color: #666
}

.teaser__meta a:active, .teaser__meta a:focus, .teaser__meta a:hover {
    color: #333;
    text-decoration: underline
}

.teaser--image .teaser__block.has-image {
    display: inline-block;
    float: left;
    width: 70%
}

.teaser--image .teaser__image {
    width: 30%;
    padding-left: 1.125em;
    display: inline-block
}

.teaser--featured {
    margin-bottom: 1.5em
}

.teaser--featured .teaser__block.has-image {
    margin-top: .75em
}

.teaser--featured .teaser__title {
    font-size: 1.57em;
    line-height: 1.00318em;
    margin-top: .47771em
}

.teaser--featured .cta-link {
    margin-top: 1.32353em;
    margin-bottom: .88235em
}

.teaser--small {
    margin-bottom: 1.5em
}

.teaser--small .teaser__title {
    font-size: 1em;
    line-height: 1.05em;
    margin-top: .375em
}

.teaser--small .teaser__label {
    margin-bottom: .75em
}

@media (min-width: 600px) {
    .teaser--profile {
        padding: 0 1.5em
    }
}

.rail .teaser--profile {
    padding: 0 !important
}

.rail .teaser--profile .teaser__block {
    border-top: none
}

.teaser--white {
    color: #fff
}

.teaser--white.teaser {
    background-color: rgba(0, 96, 156, .5);
    border-color: hsla(0, 0%, 100%, .3);
    border-top: 3px solid #fff
}

.text-overlay .teaser--white.teaser {
    border: 0;
    border-bottom: 1px solid hsla(0, 0%, 100%, .6);
    background-color: transparent;
    padding: 0 0 .75em
}

.text-overlay .teaser--white.teaser .teaser__block {
    padding: 0
}

.teaser--white .teaser__label, .teaser--white .teaser__label:active, .teaser--white .teaser__label:focus, .teaser--white .teaser__label:hover, .teaser--white .teaser__label:link, .teaser--white .teaser__label:visited, .teaser--white .teaser__meta {
    color: #fff
}

.teaser--publication.teaser--small .teaser__desc {
    font-size: .7em;
    line-height: 1.39286em
}

.teaser--publication.citation {
    line-height: 1.2em
}

.teaser--publication.citation .teaser__title {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.2em
}

.teaser--publication.citation .teaser__journal {
    font-style: italic
}

.teaser--publication.citation .teaser__block {
    padding-top: .6em;
    padding-bottom: .375em
}

.full-width-teaser {
    background-color: #f2f2f2;
    margin: 2.25em 0;
    position: relative
}

.full-width-teaser__image {
    width: 100%;
    min-height: 225px
}

@media (min-width: 600px) {
    .full-width-teaser__image {
        position: absolute;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 51%;
        min-height: 0;
        width: auto
    }
}

.full-width-teaser__block {
    padding: .75em 0 2.25em
}

@media (min-width: 600px) {
    .full-width-teaser__block {
        padding: 3em 0
    }
}

.simple-teaser-list-wrapper {
    margin-bottom: 1.5em
}

@media (min-width: 900px) {
    .simple-teaser-list-wrapper {
        margin-bottom: 3em
    }
}

.simple-teaser-list-wrapper .teaser--news, .simple-teaser-list-wrapper .teaser--small {
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: .75em
}

.simple-teaser-list-wrapper .teaser--news .teaser__block, .simple-teaser-list-wrapper .teaser--small .teaser__block {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background-color: transparent
}

.simple-teaser-list-wrapper .cta-link--right {
    margin-bottom: 0
}

.section-spacing {
    padding: .75em 0 0
}

@media (min-width: 600px) {
    .section-spacing {
        padding: 1.5em 0 0;
        padding: 2.25em 0 0
    }
}

.section-gray {
    background-color: #f2f2f2;
    padding-bottom: 1.5em
}

.section-divider__buttons {
    text-align: center
}

.section-divider__buttons .button {
    margin: 0 .75em
}

.section-center {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px
}

.section-clear {
    margin-bottom: 1.5em
}

.cta-block__header, .section-divider {
    text-align: center;
    padding: .75em 0
}

@media (min-width: 600px) {
    .cta-block__header, .section-divider {
        padding: 1.125em 0;
        padding: 2.25em 0 1.5em
    }
}

@media (min-width: 0) and (max-width: 600px) {
    .cta-block__header p, .section-divider p {
        font-size: .85em;
        line-height: 1.5em
    }
}

.section-divider__title {
    text-transform: uppercase
}

@media (min-width: 900px) {
    .section-divider__title {
        font-size: 2.2em;
        line-height: 1.15909em;
        margin-top: 0
    }
}

.section-divider--overlap {
    margin-bottom: -100px
}

.section-divider--overlap .section-divider__container {
    position: relative;
    min-height: 100px;
    background-color: #fff
}

.section-gray .section-divider--overlap .section-divider__container {
    background-color: #f2f2f2
}

.section-divider--overlap + .full-width-image {
    padding-top: 125px
}

.section-divider--overlap .section-divider__buttons {
    margin-bottom: .75em
}

.section-divider--border .section-divider__container {
    border-bottom: 3px solid #00609c
}

.links-list {
    margin: .75em 0 .225em
}

.links-list__link {
    font-weight: 600;
    display: block;
    padding: 0 0 .225em
}

.links-list__link svg {
    width: 9px;
    height: 10px;
    fill: #00609c;
    margin-left: .375em
}

@media (min-width: 900px) {
    .links-list__link svg {
        width: 16px;
        height: 12px
    }
}

.link-listing, .taxonomy-listing {
    background-color: #f2f2f2;
    margin: 1.5em 0;
    padding: 1.5em
}

.rail .link-listing, .rail .taxonomy-listing {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: .675em 0 .75em;
    border-top: 3px solid #a5a5a5
}

.link-listing__title, .taxonomy-listing__title {
    font-size: 1em;
    line-height: 1.35em;
    margin-top: 0;
    margin-bottom: .75em
}

.rail .link-listing__title, .rail .taxonomy-listing__title {
    font-size: .7em;
    line-height: 1.39286em;
    margin-top: 0;
    margin-bottom: .525em;
    text-transform: uppercase;
    color: #333
}

.link-listing__item, .taxonomy-listing__item {
    border-bottom: 1px solid #fff;
    padding-bottom: .375em;
    margin-bottom: .375em
}

.link-listing__item:last-child, .taxonomy-listing__item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0
}

.rail .link-listing__item, .rail .taxonomy-listing__item {
    border-color: #f2f2f2
}

.link-listing__item-title, .taxonomy-listing__item a:link, .taxonomy-listing__item a:visited {
    font-size: .85em;
    line-height: 1.32353em;
    margin-top: 0;
    margin-bottom: 0
}

.rail .link-listing__item-title, .rail .taxonomy-listing__item a:link, .rail .taxonomy-listing__item a:visited, .taxonomy-listing__item .rail a:link, .taxonomy-listing__item .rail a:visited {
    font-weight: 600
}

.link-listing__item-desc {
    font-size: .7em;
    line-height: 1.39286em;
    margin-bottom: 0
}

.text-overlay {
    background-color: rgba(0, 96, 156, .85);
    padding: 1.5em;
    color: #fff;
    margin-bottom: 1.5em
}

.text-overlay a:active, .text-overlay a:focus, .text-overlay a:hover, .text-overlay a:link, .text-overlay a:visited {
    color: #fff
}

.text-overlay .links-list__link svg, .text-overlay svg {
    fill: #fff
}

.text-overlay .cta-link--white {
    margin-top: 1.5em;
    margin-bottom: 0
}

.text-overlay .teaser__block {
    border: 0
}

.text-overlay .tags, .text-overlay .teaser__desc, .text-overlay .teaser__meta {
    font-weight: 500
}

.text-overlay__title {
    margin-top: 0;
    margin-bottom: .225em
}

.full-width-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    min-height: 400px;
    padding: 50px 0
}

.image-caption {
    font-size: .7em;
    line-height: 1.5em;
    color: #666;
    margin: .375em 0 1.5em
}

.featured-image {
    margin: 0 0 1.5em
}

.featured-image img {
    width: 100%
}

.inline-image.align-center {
    margin-bottom: 1.125em
}

.inline-image .image-caption {
    margin-bottom: 0
}

.colorbox-inline p {
    height: 0;
    line-height: 0;
    margin: 0
}

.image-block--1 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/image-1.jpg)
}

.image-block--2 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/image-3.jpg)
}

.image-block--3 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/image-2.jpg)
}

.image-block--4 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/article-hero.jpg)
}

.image-block--5 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/hero-2.jpg)
}

.image-block--6 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/aps-ring.jpg)
}

.image-block--7 {
    background-image: url(/themes/custom/anl_parent_theme/imgs/classified.jpg)
}

.image-block, .image-block--7 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%
}

.image-block {
    display: inline-block;
    float: left;
    margin-bottom: 1.5em
}

@media (min-width: 600px) and (max-width: 900px) {
    .image-block:first-child:nth-last-child(2), .image-block:nth-child(2):last-child {
        width: 48%;
        width: calc(50% - 12px)
    }

    .image-block:first-child:nth-last-child(2) {
        margin-right: 12px
    }

    .image-block:nth-child(2):last-child {
        margin-left: 12px
    }

    .image-block:first-child:nth-last-child(3) {
        margin-right: 12px;
        width: 48%;
        width: calc(50% - 12px)
    }

    .image-block:nth-child(2):nth-last-child(2) {
        margin-left: 12px;
        width: 48%;
        width: calc(50% - 12px)
    }

    .image-block:nth-child(3):last-child {
        width: 100%
    }

    .image-block:first-child:nth-last-child(4), .image-block:nth-child(2):nth-last-child(3), .image-block:nth-child(3):nth-last-child(2), .image-block:nth-child(4):last-child {
        width: 48%;
        width: calc(50% - 12px)
    }

    .image-block:first-child:nth-last-child(4), .image-block:nth-child(3):nth-last-child(2) {
        margin-right: 12px
    }

    .image-block:nth-child(2):nth-last-child(3), .image-block:nth-child(4):last-child {
        margin-left: 12px
    }
}

@media (min-width: 900px) {
    .image-block:first-child:nth-last-child(2), .image-block:nth-child(2):last-child {
        width: 48%;
        width: calc(50% - 12px)
    }

    .image-block:first-child:nth-last-child(2) {
        margin-right: 12px
    }

    .image-block:nth-child(2):last-child {
        margin-left: 12px
    }

    .image-block:first-child:nth-last-child(3), .image-block:nth-child(2):nth-last-child(2), .image-block:nth-child(3):last-child {
        width: 31%;
        width: calc(33% - 14px);
        margin-left: 12px;
        margin-right: 12px
    }

    .image-block:first-child:nth-last-child(3) {
        margin-left: 0
    }

    .image-block:nth-child(3):last-child {
        margin-right: 0
    }

    .image-block:first-child:nth-last-child(4), .image-block:nth-child(2):nth-last-child(3), .image-block:nth-child(3):nth-last-child(2), .image-block:nth-child(4):last-child {
        width: calc(25% - 18px);
        margin-left: 12px;
        margin-right: 12px
    }

    .image-block:first-child:nth-last-child(4) {
        margin-left: 0
    }

    .image-block:nth-child(4):last-child {
        margin-right: 0
    }
}

.image-block__overlay {
    background-color: rgba(0, 96, 156, .85);
    padding: 1.5em;
    color: #fff;
    width: 100%
}

.image-block__link {
    transition: all .3s ease;
    display: block;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    min-height: 400px
}

.image-block__link:active, .image-block__link:focus, .image-block__link:hover {
    text-decoration: none
}

.image-block__link:active .text-overlay__title, .image-block__link:focus .text-overlay__title, .image-block__link:hover .text-overlay__title {
    color: #fff;
    text-decoration: underline
}

.image-block__link:active .image-block__overlay, .image-block__link:focus .image-block__overlay, .image-block__link:hover .image-block__overlay {
    background-color: rgba(0, 96, 156, .9)
}

.cta-block {
    background-color: #333;
    color: #fff;
    padding: 3em 0
}

.cta-block-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.cta-block__header {
    padding-top: 0;
    padding-bottom: 0
}

.cta-block__header p {
    font-size: 1em;
    line-height: 1.5em
}

.cta-block__title {
    margin-top: 0;
    text-transform: uppercase
}

@media (min-width: 600px) {
    .cta-block__title {
        font-size: 2.2em;
        line-height: 1.15909em
    }
}

.cta-block__desc {
    margin-bottom: 1.5em
}

.cta-block__buttons {
    margin-top: .375em
}

.cta-block__buttons .button {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5em
}

.cta-block__buttons .contact {
    background-color: transparent;
    border: 0;
    padding: 0
}

.video {
    position: relative;
    padding-bottom: 56.25%;
    margin: 1.5em 0
}

.video iframe, .video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.video-embed-field-responsive-video {
    margin: 0 0 1.5em
}

.video-embed-field-responsive-video .video {
    padding-bottom: 0;
    height: auto;
    position: static;
    margin: 0
}

.blockquote, blockquote {
    font-size: 1.26em;
    line-height: 1.4881em;
    font-family: adobe-garamond-pro, Georgia, Times New Roman, DejaVu Serif, serif;
    font-style: italic;
    background-color: #f2f2f2;
    padding: 1.125em;
    margin-top: .525em;
    margin-bottom: .525em
}

.blockquote-caption {
    font-size: .6em;
    line-height: 2em;
    color: #666;
    font-style: regular;
    font-family: proxima-nova, Helvetica, Arial, Nimbus Sans L, sans-serif;
    margin: 1.125em 0 0;
    border-top: 1px solid #77b300;
    padding-top: .375em
}

blockquote p:last-child {
    margin-bottom: 0
}

.app-header, .basic-header, .profile-header {
    margin-bottom: 1.5em
}

.basic-header--image.app-header, .basic-header--image.profile-header, .basic-header--video.app-header, .basic-header--video.profile-header, .basic-header.basic-header--image, .basic-header.basic-header--video {
    border-bottom: 0;
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%
}

@media (min-width: 600px) {
    .basic-header--image.app-header, .basic-header--image.profile-header, .basic-header--video.app-header, .basic-header--video.profile-header, .basic-header.basic-header--image, .basic-header.basic-header--video {
        padding-top: 4.5em
    }
}

@media (min-width: 900px) {
    .basic-header--image.app-header, .basic-header--image.profile-header, .basic-header--video.app-header, .basic-header--video.profile-header, .basic-header.basic-header--image, .basic-header.basic-header--video {
        padding-top: 6em
    }
}

.basic-header--video.app-header, .basic-header--video.profile-header, .basic-header.basic-header--video {
    position: relative;
    overflow: hidden
}

.basic-header--video.app-header .basic-header__content, .basic-header--video.profile-header .basic-header__content, .basic-header.basic-header--video .basic-header__content {
    position: relative;
    z-index: 2
}

@media (min-width: 700px) {
    .basic-header--video.video-provider--html_5.app-header .ambient-video--container, .basic-header--video.video-provider--html_5.profile-header .ambient-video--container, .basic-header.basic-header--video.video-provider--html_5 .ambient-video--container {
        height: auto
    }
}

@media (min-width: 1100px) {
    .basic-header--video.video-provider--html_5.app-header .ambient-video--container, .basic-header--video.video-provider--html_5.profile-header .ambient-video--container, .basic-header.basic-header--video.video-provider--html_5 .ambient-video--container {
        height: auto
    }
}

@media (min-width: 1400px) {
    .basic-header--video.video-provider--html_5.app-header .ambient-video--container, .basic-header--video.video-provider--html_5.profile-header .ambient-video--container, .basic-header.basic-header--video.video-provider--html_5 .ambient-video--container {
        height: auto
    }
}

.basic-header--video.app-header .ambient-video--container:before, .basic-header--video.profile-header .ambient-video--container:before, .basic-header.basic-header--video .ambient-video--container:before {
    background-color: rgba(51, 51, 51, .7);
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1
}

.basic-header--video.app-header .basic-header__video-controls, .basic-header--video.profile-header .basic-header__video-controls, .basic-header.basic-header--video .basic-header__video-controls {
    display: none;
    visibility: hidden
}

@media (min-width: 700px) {
    .basic-header--video.app-header .basic-header__video-controls, .basic-header--video.profile-header .basic-header__video-controls, .basic-header.basic-header--video .basic-header__video-controls {
        display: block;
        visibility: visible;
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 3
    }
}

.basic-header--video.app-header .basic-header__video-control, .basic-header--video.profile-header .basic-header__video-control, .basic-header.basic-header--video .basic-header__video-control {
    display: none;
    visibility: hidden
}

.basic-header--video.app-header .basic-header__video-control.is-active, .basic-header--video.profile-header .basic-header__video-control.is-active, .basic-header.basic-header--video .basic-header__video-control.is-active {
    display: block;
    visibility: visible
}

.basic-header--video.app-header .basic-header__video-control .fa, .basic-header--video.profile-header .basic-header__video-control .fa, .basic-header.basic-header--video .basic-header__video-control .fa {
    margin: 0
}

.l-basic-header {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: both
}

@media (min-width: 600px) {
    .basic-header--image .l-basic-header, .basic-header--video .l-basic-header {
        width: 65.71429%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: left
    }
}

@media (min-width: 900px) {
    .basic-header--image .l-basic-header, .basic-header--video .l-basic-header {
        width: 74.28571%;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: left
    }
}

@media (min-width: 1100px) {
    .basic-header--image .l-basic-header, .basic-header--video .l-basic-header {
        width: auto;
        float: left;
        margin-right: -100%;
        margin-left: 0;
        clear: left
    }
}

.app-header__block, .basic-header__block, .profile-header__block {
    margin-top: 4.5em;
    margin-bottom: 0
}

.basic-header.basic-header--image .app-header__block, .basic-header.basic-header--image .basic-header__block, .basic-header.basic-header--image .profile-header__block, .basic-header.basic-header--video .app-header__block, .basic-header.basic-header--video .basic-header__block, .basic-header.basic-header--video .profile-header__block {
    margin-bottom: 0;
    padding: 2.625em 1.5em;
    background-color: rgba(0, 96, 156, .8);
    color: #fff;
    margin: 0 -1.5em
}

.basic-header.basic-header--image .app-header__block a:active, .basic-header.basic-header--image .app-header__block a:focus, .basic-header.basic-header--image .app-header__block a:hover, .basic-header.basic-header--image .app-header__block a:link, .basic-header.basic-header--image .app-header__block a:visited, .basic-header.basic-header--image .basic-header__block a:active, .basic-header.basic-header--image .basic-header__block a:focus, .basic-header.basic-header--image .basic-header__block a:hover, .basic-header.basic-header--image .basic-header__block a:link, .basic-header.basic-header--image .basic-header__block a:visited, .basic-header.basic-header--image .profile-header__block a:active, .basic-header.basic-header--image .profile-header__block a:focus, .basic-header.basic-header--image .profile-header__block a:hover, .basic-header.basic-header--image .profile-header__block a:link, .basic-header.basic-header--image .profile-header__block a:visited, .basic-header.basic-header--video .app-header__block a:active, .basic-header.basic-header--video .app-header__block a:focus, .basic-header.basic-header--video .app-header__block a:hover, .basic-header.basic-header--video .app-header__block a:link, .basic-header.basic-header--video .app-header__block a:visited, .basic-header.basic-header--video .basic-header__block a:active, .basic-header.basic-header--video .basic-header__block a:focus, .basic-header.basic-header--video .basic-header__block a:hover, .basic-header.basic-header--video .basic-header__block a:link, .basic-header.basic-header--video .basic-header__block a:visited, .basic-header.basic-header--video .profile-header__block a:active, .basic-header.basic-header--video .profile-header__block a:focus, .basic-header.basic-header--video .profile-header__block a:hover, .basic-header.basic-header--video .profile-header__block a:link, .basic-header.basic-header--video .profile-header__block a:visited {
    color: #fff
}

.app-header__tag, .basic-header__tag, .profile-header__tag {
    font-size: .85em;
    line-height: 1.41176em;
    display: inline-block;
    margin-bottom: 1.5em;
    font-weight: 600;
    text-transform: uppercase
}

.app-header__title-block .app-header__title, .basic-header__title {
    margin-top: 0;
    margin-bottom: 0
}

@media (min-width: 600px) {
    .app-header__title-block .app-header__title, .basic-header__title {
        font-size: 2.5em;
        line-height: 1.08em
    }
}

.basic-header__subtitle, .profile-header__job-title {
    font-size: 1.26em;
    line-height: 1.30952em;
    margin-bottom: 0;
    margin-top: .225em;
    font-family: adobe-garamond-pro, Georgia, Times New Roman, DejaVu Serif, serif;
    font-style: italic;
    font-weight: 400
}

@media (min-width: 600px) {
    .basic-header__subtitle, .profile-header__job-title {
        font-size: 1.57em;
        line-height: 1.19427em
    }
}

.app-header__title-block .app-header__title, .app-header__title-block .basic-header__title, .app-header__title-block .basic-header__title-block .app-header__title, .basic-header__title-block .app-header__title-block .app-header__title, .basic-header__title-block .basic-header__title {
    display: inline-block
}

.app-header__title-block .app-header__acronym, .app-header__title-block .basic-header__acronym, .app-header__title-block .basic-header__title-block .app-header__acronym, .basic-header__title-block .app-header__title-block .app-header__acronym, .basic-header__title-block .basic-header__acronym {
    position: relative;
    top: -4px;
    display: inline-block;
    background-color: #f2f2f2;
    padding: 0 .375em;
    margin-left: .375em;
    color: #00609c
}

@media (min-width: 600px) {
    .landing-header.basic-header--image, .landing-header.basic-header--video {
        padding-top: 7.5em
    }
}

@media (min-width: 900px) {
    .landing-header.basic-header--image, .landing-header.basic-header--video {
        padding-top: 10.5em
    }
}

.app-header__link-wrapper, .basic-header__link-wrapper {
    margin-top: .75em;
    display: block
}

.app-header__link, .basic-header__link {
    display: block
}

.app-header__link .fa, .basic-header__link .fa {
    margin-left: .375em
}

.app-header__link svg, .basic-header__link svg {
    position: relative;
    top: 2px;
    height: 15px;
    width: 15px;
    fill: #00609c;
    margin-left: .225em
}

.reference-teaser {
    margin-top: .75em
}

.rail .reference-teaser {
    margin: .525em 0
}

.collection-block .reference-teaser {
    margin: .525em 0 .75em
}

.reference-teaser__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.26em;
    line-height: 1.19048em
}

.contact--main .reference-teaser__title, .contact .reference-teaser__title {
    font-size: .7em;
    line-height: 1.39286em
}

.rail .reference-teaser__title {
    font-size: .85em;
    line-height: 1.23529em
}

.collection-block .reference-teaser__title {
    font-size: 1em;
    line-height: 1.35em
}

.profile-reference-link, .reference-teaser__link {
    font-size: .85em;
    line-height: 1.32353em;
    display: block;
    margin-bottom: .375em
}

.profile-reference-link .fa, .reference-teaser__link .fa {
    margin-left: .375em
}

.rail .profile-reference-link, .rail .reference-teaser__link {
    font-size: .7em;
    line-height: 1.39286em;
    margin-top: .375em;
    margin-bottom: 0
}

.collection-block .profile-reference-link, .collection-block .reference-teaser__link, .contact--main .profile-reference-link, .contact--main .reference-teaser__link, .contact .profile-reference-link, .contact .reference-teaser__link {
    font-size: .7em;
    line-height: 1.39286em
}

.reference-teaser__text {
    font-size: .85em;
    line-height: 1.32353em;
    margin-bottom: 0
}

.contact {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    border-top: 3px solid #0082ca;
    padding: .675em .75em .75em;
    margin-bottom: .75em
}

.contact__label {
    font-size: .7em;
    line-height: 1.39286em;
    margin-top: 0;
    margin-bottom: .375em;
    text-transform: uppercase;
    color: #333
}

.contact__label a:link, .contact__label a:visited {
    color: #666
}

.contact__label a:active, .contact__label a:focus, .contact__label a:hover {
    color: #333;
    text-decoration: underline
}

.contact__title, .key-info__title {
    margin-top: 0;
    margin-bottom: .225em;
    font-size: 1em;
    line-height: 1.275em
}

.contact__block, .key-info__block {
    margin-bottom: .525em
}

.contact__block--profile {
    margin-top: 0
}

.contact__name {
    margin-top: 0;
    margin-bottom: 0;
    font-size: .85em;
    line-height: 1.32353em
}

.rail .contact__name {
    font-size: 1em;
    line-height: 1.05em;
    margin-top: .375em;
    margin-bottom: .375em
}

.contact__info, .key-info__para {
    font-size: .7em;
    line-height: 1.39286em;
    display: inline-block;
    float: left
}

.contact__info:after, .key-info__para:after {
    content: "|";
    display: inline-block;
    margin: 0 .375em 0 .525em;
    color: #333;
    text-decoration: none
}

.contact__info:last-child:after, .key-info__para:last-child:after {
    display: none
}

.contact__link, .key-info__link {
    font-size: .7em;
    line-height: 1.39286em;
    display: block;
    margin-top: .375em
}

.contact__link svg, .key-info__link svg {
    position: relative;
    top: 2px;
    height: 12px;
    width: 12px;
    fill: #00609c;
    margin-right: .225em
}

.contact--main .contact__label {
    font-size: 1.26em;
    line-height: 1.19048em;
    text-transform: none;
    color: #333
}

.contact--main .contact__label a:link, .contact--main .contact__label a:visited {
    color: #00609c
}

.contact--main .contact__label a:active, .contact--main .contact__label a:focus, .contact--main .contact__label a:hover {
    color: #004169
}

.contact--main .contact__name {
    font-size: 1em;
    line-height: 1.35em
}

.contact--main .contact__name a:active, .contact--main .contact__name a:focus, .contact--main .contact__name a:hover, .contact--main .contact__name a:link, .contact--main .contact__name a:visited {
    color: #333
}

.contact--main .contact__info, .contact--main .contact__link, .contact--main .key-info__link, .contact--main .key-info__para {
    font-size: .85em;
    line-height: 1.32353em
}

.related-teaser {
    margin: .75em 0;
    padding-bottom: .75em;
    border-bottom: 1px solid #f2f2f2
}

@media (min-width: 600px) {
    .related-teaser {
        border-bottom: 0;
        padding-bottom: 0
    }
}

.related-teaser__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.26em;
    line-height: 1.19048em
}

.rail .related-teaser__title {
    font-size: 1em;
    line-height: 1.05em;
    margin-top: .375em;
    margin-bottom: .375em
}

.related-teaser__link {
    font-size: .85em;
    line-height: 1.41176em;
    display: block
}

.related-teaser__link svg {
    position: relative;
    top: 2px;
    height: 12px;
    width: 12px;
    fill: #00609c;
    margin-left: .225em
}

.related-teaser__text {
    font-size: .85em;
    line-height: 1.32353em;
    margin-bottom: .15em
}

.rail .related-teaser__text {
    font-size: .7em;
    line-height: 1.39286em;
    margin-bottom: 0
}

.related-teaser__image {
    float: right;
    width: 30%;
    margin-left: 12px;
    margin-bottom: 12px;
    border: 1px solid #f2f2f2
}

.media-bio .related-teaser__image {
    float: none;
    width: auto;
    margin: 0
}

.related-teaser.has-image .related-teaser__block {
    width: 65%;
    width: calc(70% - 12px)
}

.related-teaser.has-image.media-bio .related-teaser__block {
    width: auto
}

.related-teaser--small .related-teaser__title {
    font-size: 1em;
    line-height: 1.2em
}

.related-teaser--small .related-teaser__text {
    font-size: .7em;
    line-height: 1.39286em
}

.related-teaser--small .related-teaser__link {
    font-size: .7em;
    line-height: 1.5em
}

.related-teaser--small .related-teaser__image {
    display: none
}

@media (min-width: 600px) {
    .related-teaser--small .related-teaser__image {
        display: block;
        width: 20%
    }
}

@media (min-width: 600px) {
    .related-teaser--small.related-teaser--application .related-teaser__image, .related-teaser--small.related-teaser--profile .related-teaser__image {
        width: 30%
    }
}

.project-teaser {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    margin: .75em 0
}

.project-teaser__block {
    border-top: 3px solid #0082ca;
    padding: .675em .75em .75em
}

.project-teaser__label {
    font-size: .7em;
    line-height: 1.60714em;
    margin-top: 0;
    margin-bottom: .225em;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: #333
}

.project-teaser__text {
    font-size: .85em;
    line-height: 1.32353em;
    margin-bottom: 0
}

.project-teaser__title {
    margin-top: .375em;
    margin-bottom: .375em
}

.project-teaser__image {
    float: right;
    width: 30%;
    margin-left: 12px
}

@media (min-width: 600px) {
    .project-teaser__image {
        float: none;
        display: block;
        width: 100%;
        margin-left: 0
    }
}

.project-teaser--small .project-teaser__title {
    font-size: 1em;
    line-height: 1.05em
}

.project-teaser--small .project-teaser__text {
    font-size: .7em;
    line-height: 1.39286em;
    margin-bottom: 0
}

.boilerplate-wrapper {
    margin-top: 1.5em;
    padding-top: 1.5em
}

.boilerplate {
    margin-bottom: 1.5em;
    color: #666
}

.related-organization-list li {
    font-size: .85em;
    line-height: 1.23529em;
    font-weight: 600;
    padding: .375em 0
}

.article__byline {
    text-transform: uppercase;
    color: #666;
    font-weight: 700
}

.article__byline + .lede {
    margin-top: .75em
}

.article__byline span, .article__byline span div {
    display: inline-block
}

.article-footer {
    margin-top: 1.5em;
    margin-bottom: .75em
}

.article-footer__title {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    text-transform: uppercase
}

.related-main {
    margin: 1.5em 0;
    padding-top: 1.5em;
    border-top: 1px solid #d1d1d1
}

.related-main__title {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: .375em;
    text-transform: uppercase
}

@media (min-width: 900px) {
    .related-main__list li:nth-of-type(odd) {
        width: 48.57143%;
        clear: right;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%;
        clear: both
    }

    .related-main__list li:nth-of-type(2n) {
        width: 48.57143%;
        clear: right;
        float: right;
        margin-right: 0
    }
}

.social-media {
    padding-top: .75em
}

.social-media__list li {
    display: inline-block;
    margin-left: 6px
}

.social-media__list li svg {
    fill: #666;
    height: 32px;
    transition: all .2s ease-in-out;
    width: 32px
}

.social-media__list li svg:active, .social-media__list li svg:focus, .social-media__list li svg:hover {
    transform: scale(1.2)
}

.social-media__list li.twitter a, .social-media__list li.twitter div {
    cursor: pointer
}

.social-media__list li.twitter a:active svg, .social-media__list li.twitter a:focus svg, .social-media__list li.twitter a:hover svg, .social-media__list li.twitter div:active svg, .social-media__list li.twitter div:focus svg, .social-media__list li.twitter div:hover svg {
    fill: #00aced
}

.social-media__list li.flickr a, .social-media__list li.flickr div {
    cursor: pointer
}

.social-media__list li.flickr a:active svg, .social-media__list li.flickr a:focus svg, .social-media__list li.flickr a:hover svg, .social-media__list li.flickr div:active svg, .social-media__list li.flickr div:focus svg, .social-media__list li.flickr div:hover svg {
    fill: #ff0084
}

.social-media__list li.facebook a, .social-media__list li.facebook div {
    cursor: pointer
}

.social-media__list li.facebook a:active svg, .social-media__list li.facebook a:focus svg, .social-media__list li.facebook a:hover svg, .social-media__list li.facebook div:active svg, .social-media__list li.facebook div:focus svg, .social-media__list li.facebook div:hover svg {
    fill: #3b5998
}

.social-media__list li.youtube a, .social-media__list li.youtube div {
    cursor: pointer
}

.social-media__list li.youtube a:active svg, .social-media__list li.youtube a:focus svg, .social-media__list li.youtube a:hover svg, .social-media__list li.youtube div:active svg, .social-media__list li.youtube div:focus svg, .social-media__list li.youtube div:hover svg {
    fill: #b00
}

.social-media__list li.linkedin a, .social-media__list li.linkedin div {
    cursor: pointer
}

.social-media__list li.linkedin a:active svg, .social-media__list li.linkedin a:focus svg, .social-media__list li.linkedin a:hover svg, .social-media__list li.linkedin div:active svg, .social-media__list li.linkedin div:focus svg, .social-media__list li.linkedin div:hover svg {
    fill: #007bb6
}

.social-media__list li.instagram a, .social-media__list li.instagram div {
    cursor: pointer
}

.social-media__list li.instagram a:active svg, .social-media__list li.instagram a:focus svg, .social-media__list li.instagram a:hover svg, .social-media__list li.instagram div:active svg, .social-media__list li.instagram div:focus svg, .social-media__list li.instagram div:hover svg {
    fill: #bc2a8d
}

.social-media__list li.email a, .social-media__list li.email div {
    cursor: pointer
}

.social-media__list li.email a:active svg, .social-media__list li.email a:focus svg, .social-media__list li.email a:hover svg, .social-media__list li.email div:active svg, .social-media__list li.email div:focus svg, .social-media__list li.email div:hover svg {
    fill: #77b300
}

.share-block {
    background-color: transparent;
    border: 0;
    padding: .675em 0 .75em;
    border-top: 3px solid #a5a5a5
}

.share-block .rail-title {
    margin-bottom: .75em
}

nav + .share-block {
    margin-top: 1.5em
}

h3.social-media, h4.social-media, h5.social-media {
    font-size: 1em;
    line-height: 1.35em
}

h3.social-media .fa, h4.social-media .fa, h5.social-media .fa {
    margin-right: 5px
}

h3.social-media.twitter, h4.social-media.twitter, h5.social-media.twitter {
    border-bottom: 3px solid #00aced;
    color: #00aced
}

h3.social-media.twitter a, h4.social-media.twitter a, h5.social-media.twitter a {
    color: #00aced
}

h3.social-media.flickr, h4.social-media.flickr, h5.social-media.flickr {
    border-bottom: 3px solid #ff0084;
    color: #ff0084
}

h3.social-media.flickr a, h4.social-media.flickr a, h5.social-media.flickr a {
    color: #ff0084
}

h3.social-media.facebook, h4.social-media.facebook, h5.social-media.facebook {
    border-bottom: 3px solid #3b5998;
    color: #3b5998
}

h3.social-media.facebook a, h4.social-media.facebook a, h5.social-media.facebook a {
    color: #3b5998
}

h3.social-media.youtube, h4.social-media.youtube, h5.social-media.youtube {
    border-bottom: 3px solid #b00;
    color: #b00
}

h3.social-media.youtube a, h4.social-media.youtube a, h5.social-media.youtube a {
    color: #b00
}

h3.social-media.linkedin, h4.social-media.linkedin, h5.social-media.linkedin {
    border-bottom: 3px solid #007bb6;
    color: #007bb6
}

h3.social-media.linkedin a, h4.social-media.linkedin a, h5.social-media.linkedin a {
    color: #007bb6
}

h3.social-media.instagram, h4.social-media.instagram, h5.social-media.instagram {
    border-bottom: 3px solid #bc2a8d;
    color: #bc2a8d
}

h3.social-media.instagram a, h4.social-media.instagram a, h5.social-media.instagram a {
    color: #bc2a8d
}

h3.social-media.email, h4.social-media.email, h5.social-media.email {
    border-bottom: 3px solid #77b300;
    color: #77b300
}

h3.social-media.email a, h4.social-media.email a, h5.social-media.email a {
    color: #77b300
}

.contact--single-button {
    margin-bottom: .75em
}

@media (min-width: 900px) {
    .contact--single-button {
        margin-bottom: 0
    }
}

.contact--single-button .button {
    font-size: .7em;
    line-height: 1.39286em;
    margin-bottom: .75em
}

.contact--single-button__info {
    margin-top: 0;
    margin-bottom: 0;
    font-size: .85em;
    line-height: 1.32353em;
    color: #fff;
    text-align: center
}

.contact--single-button__info a:active, .contact--single-button__info a:focus, .contact--single-button__info a:hover, .contact--single-button__info a:link, .contact--single-button__info a:visited {
    color: #fff
}

.event__metadata {
    font-size: 1.26em;
    line-height: 1.36905em;
    font-weight: 700
}

.event__metadata + .lede {
    margin-top: 1.125em
}

.event__metatdata-group {
    margin-bottom: .75em
}

@media (min-width: 900px) {
    .app-header__block, .profile-header__block {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }
}

.app-header__image, .profile-header__headshot {
    width: 150px;
    margin: 0 0 .75em;
    padding: 0;
    overflow: hidden
}

@media (min-width: 900px) {
    .app-header__image, .profile-header__headshot {
        width: 300px;
        margin: .225em 1.5em 0 0;
        clear: both;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
}

@media (min-width: 900px) {
    .app-header__text, .profile-header__text {
        width: auto;
        clear: both
    }
}

.profile-header__name {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.57em;
    line-height: 1.19427em
}

@media (min-width: 600px) {
    .profile-header__name {
        font-size: 1.9em;
        line-height: 1.14474em
    }
}

.profile-reference-list {
    font-size: .85em;
    line-height: 1.32353em;
    margin-top: .375em
}

.profile-reference-list li {
    border-bottom: 0
}

.profile-reference-list__item {
    display: inline;
    color: #666;
    border-bottom: 0
}

.profile-reference-title {
    margin-top: .225em;
    margin-bottom: 0;
    font-size: .85em;
    line-height: 1.23529em;
    font-weight: 700;
    display: inline-block
}

.curated-block {
    border-top: 3px solid #00609c;
    background-color: #f2f2f2;
    padding: .75em .75em 1.5em;
    margin-bottom: 1.5em
}

.l-rail .curated-block {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    border-top: 3px solid #00609c
}

.curated-block .teaser--image .teaser__block.has-image {
    width: 100%;
    float: none;
    display: block
}

.curated-block .teaser--image .teaser__image {
    display: none
}

.curated-block .related-teaser, .curated-block .teaser {
    margin: 0;
    padding-bottom: 0
}

.l-rail .curated-block .related-teaser.has-image .related-teaser__block {
    width: 100%
}

.curated-block .teaser__block {
    border-top: 0;
    margin-bottom: 0;
    padding-top: 0
}

.l-rail .curated-block .related-teaser__image {
    width: 100%;
    float: none;
    margin-left: 0
}

.curated-block .teaser--profile {
    padding: 0
}

.curated-block .teaser--profile .teaser__title {
    font-size: 1.26em;
    line-height: 1.19048em
}

.curated-block .related-teaser--application .related-teaser__image, .curated-block .teaser--profile .teaser__image {
    width: 30%;
    max-width: 125px;
    float: right;
    margin-left: 12px
}

.curated-block__label {
    font-size: .7em;
    line-height: 1.39286em;
    margin-top: 0;
    margin-bottom: .75em;
    text-transform: uppercase;
    color: #333
}

.org-list {
    margin-top: 1.5em;
    margin-bottom: 1.5em
}

.org-footer-block {
    font-size: .85em;
    line-height: 1.41176em
}

.org-footer-block ul {
    margin-top: 0
}

.pager {
    margin: 3em 0 1.5em;
    padding-top: .75em;
    border-top: 1px solid #a5a5a5
}

.pager__items {
    list-style: none;
    margin: 0;
    padding-left: 0
}

.pager__item {
    display: inline-block;
    margin-right: .15em
}

.pager__item a:link, .pager__item a:visited {
    font-size: .85em;
    line-height: 1.41176em;
    display: block;
    background-color: transparent;
    border: 1px solid #d1d1d1;
    padding: .15em .6em;
    margin-bottom: .375em
}

.pager__item a:active, .pager__item a:focus, .pager__item a:hover {
    background-color: #f2f2f2
}

.pager__item.is-active a {
    background-color: #00609c;
    border-color: #00609c;
    color: #fff
}

.pager__item.is-active a:active, .pager__item.is-active a:focus, .pager__item.is-active a:hover {
    text-decoration: none;
    cursor: pointer
}

.listing-view-list > li {
    margin-top: 1.125em;
    padding-bottom: 1.125em;
    border-bottom: 1px solid #f2f2f2
}

.listing-view-list > li:last-child {
    border-bottom: 0
}

.listing-view-list .related-teaser .related-teaser__image {
    width: 25%
}

@media (min-width: 425px) {
    .listing-view-list .related-teaser .related-teaser__image {
        width: 22%
    }
}

.listing-view-list .related-teaser.related-teaser--application .related-teaser__image, .listing-view-list .related-teaser.related-teaser--profile .related-teaser__image {
    width: 20%
}

@media (min-width: 425px) {
    .listing-view-list .related-teaser.related-teaser--application .related-teaser__image, .listing-view-list .related-teaser.related-teaser--profile .related-teaser__image {
        width: 15%
    }
}

.listing-view-list .teaser {
    margin-bottom: 0
}

.listing-view-list .teaser__block {
    border: 0;
    padding-top: 0
}

.listing-view-list .related-teaser {
    margin-bottom: 0
}

.listing-filters {
    padding: .75em .75em .375em;
    border: 1px solid #d1d1d1;
    margin-bottom: 1.5em
}

.listing-filters .form-row, .listing-filters .glossary-nav {
    transition: .5s ease .3s;
    max-height: 0;
    overflow: hidden;
    visibility: hidden
}

.listing-filters .form-row.is-active, .listing-filters .glossary-nav.is-active {
    transition: .5s ease .3s;
    max-height: 999px;
    visibility: visible
}

@media (min-width: 900px) {
    .listing-filters {
        padding: .75em
    }

    .listing-filters .form-row, .listing-filters .glossary-nav {
        max-height: none;
        visibility: visible;
        overflow: visible
    }
}

.listing-filters__title {
    font-size: .85em;
    line-height: 1.32353em;
    margin-top: 0;
    text-transform: uppercase;
    cursor: pointer
}

.listing-filters__title .fa {
    transition: .5s ease .3s;
    float: right
}

.listing-filters__title.is-active .fa {
    transition: .5s ease .3s;
    transform: rotate(45deg)
}

@media (min-width: 900px) {
    .listing-filters__title {
        cursor: auto
    }

    .listing-filters__title .fa {
        display: none
    }
}

.glossary-nav__list li {
    display: inline-block
}

.glossary-nav__list li.is-active a {
    background-color: #00609c;
    border-color: #00609c;
    color: #fff
}

.glossary-nav__list li.is-active a:active, .glossary-nav__list li.is-active a:focus, .glossary-nav__list li.is-active a:hover {
    text-decoration: none;
    cursor: pointer
}

.glossary-nav__list a {
    display: inline-block
}

.glossary-nav__list a:link, .glossary-nav__list a:visited {
    font-size: 1em;
    line-height: 1.5em;
    background-color: transparent;
    border: 1px solid #d1d1d1;
    padding: .675em;
    margin-bottom: .225em
}

.glossary-nav__list a:active, .glossary-nav__list a:focus, .glossary-nav__list a:hover {
    background-color: #f2f2f2
}

@media (min-width: 600px) {
    .glossary-nav__list a:link, .glossary-nav__list a:visited {
        font-size: .85em;
        line-height: 1.41176em;
        padding: .15em .375em
    }
}

.alert {
    margin-top: .75em;
    margin-bottom: .75em;
    font-size: 1em;
    line-height: 1.275em;
}

.alert__label {
    font-weight: 700;
    text-transform: uppercase;
    margin-right: .15em
}

.alert--emergency .alert__label {
    color: #cd202c
}

.alert--warning .fa {
    color: #ff7900
}

.alert--notification .fa {
    color: #f8b200
}

.publication-authors .publication-header {
    margin-top: 0
}

.collection {
    margin-top: .75em;
    padding-top: .75em
}

.collection-block {
    margin-bottom: 1.5em
}

.collection-block .related-teaser {
    margin-bottom: .75em
}

.collection-block .related-teaser .related-teaser__title {
    font-size: 1em;
    line-height: 1.2em
}

.collection-block .related-teaser .related-teaser__text {
    font-size: .7em;
    line-height: 1.39286em
}

.collection-block .related-teaser .related-teaser__link {
    font-size: .7em;
    line-height: 1.5em
}

.collection-block .related-teaser--org .teaser__label {
    margin-bottom: .75em
}

.collection-block .related-teaser--org .teaser__title {
    font-size: 1em;
    line-height: 1.2em;
    margin-top: .375em
}

.collection-block .link-listing {
    margin-top: 0
}

.collection-block .curated-block {
    border-top: 0
}

.collection-block .curated-block .related-teaser.has-image .related-teaser__block {
    width: 100%
}

.collection-block .curated-block .related-teaser__image {
    width: 100%;
    float: none;
    margin-left: 0
}

.collection-block .teaser--image .teaser__block.has-image {
    width: 100%;
    float: none;
    display: block
}

.collection-block .teaser--image .teaser__image {
    display: none
}

.collection__title {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: .59524em;
    text-transform: uppercase
}

.image-gallery__slide {
    margin: 0 .225em
}

.image-gallery__slide img {
    margin: auto
}

.image-gallery__title {
    font-size: .85em;
    line-height: 1.76471em;
    margin-top: 0;
    text-transform: uppercase;
    text-align: center
}

.image-gallery-four .slick-slide {
    margin: 0 .8em
}

.app-header__image {
    border: 1px solid #f2f2f2;
    width: 75px
}

@media (min-width: 900px) {
    .app-header__image {
        width: 200px
    }
}

.app-header__image img {
    width: 100%
}

.key-info__block {
    margin-top: .525em
}

.key-info__para {
    font-size: .85em;
    line-height: 1.32353em;
    margin-bottom: .45em;
    display: block;
    clear: both;
    text-transform: capitalize
}

.key-info__para:after {
    content: "";
    display: none
}

.key-info__label {
    font-weight: 600;
    margin-right: 2px
}

.key-info__item + .key-info__item {
    display: block;
    margin-top: 10px
}

.contacts-detail {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 1.5em;
    margin-top: 1.5em
}

.contacts-detail:last-child {
    border-bottom: 0
}

.contacts-detail__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.26em;
    line-height: 1.19048em
}

.contacts-detail__info {
    margin-bottom: 0
}

.contacts-detail__label {
    font-weight: 700
}

.contacts-detail__link {
    font-size: 1em;
    line-height: 1.5em;
    display: block;
    margin-top: .375em
}

.contacts-detail__link svg {
    position: relative;
    top: 2px;
    height: 12px;
    width: 12px;
    fill: #00609c;
    margin-right: .225em
}

.call-to-action {
    padding: 0 0 0 1.125em;
    margin: .75em 0;
    border-left: 15px solid #00609c
}

.rail .call-to-action {
    margin: 0
}

.call-to-action__title {
    font-size: 1.26em;
    line-height: 1.19048em;
    margin-top: 0;
    margin-bottom: 0
}

.rail .call-to-action__title {
    font-size: 1em;
    line-height: 1.05em;
    margin-top: .375em;
    margin-bottom: .375em
}

.call-to-action__teaser {
    font-size: .85em;
    line-height: 1.32353em;
    margin-bottom: .225em
}

.call-to-action__link {
    font-size: .85em;
    line-height: 1.32353em;
    text-transform: uppercase;
    font-weight: 700
}

.call-to-action__link .fa {
    font-size: .7em;
    line-height: 1.39286em;
    margin-left: .225em
}

.call-to-action__link.button {
    margin-top: .75em
}

.call-to-action--green {
    border-color: #77b300
}

.call-to-action--green .call-to-action__link:link, .call-to-action--green .call-to-action__link:visited {
    color: #699e00
}

.call-to-action--green .call-to-action__link.button {
    background-color: #699e00
}

.call-to-action--green .call-to-action__link.button:link, .call-to-action--green .call-to-action__link.button:visited {
    color: #fff
}

.call-to-action--teal {
    border-color: #00a19c
}

.call-to-action--teal .call-to-action__link:link, .call-to-action--teal .call-to-action__link:visited {
    color: #00a19c
}

.call-to-action--teal .call-to-action__link.button {
    background-color: #00a19c
}

.call-to-action--teal .call-to-action__link.button:link, .call-to-action--teal .call-to-action__link.button:visited {
    color: #fff
}

.call-to-action--yellow {
    border-color: #f8b200
}

.call-to-action--yellow .call-to-action__link:link, .call-to-action--yellow .call-to-action__link:visited {
    color: #c28b00
}

.call-to-action--yellow .call-to-action__link.button {
    background-color: #c28b00
}

.call-to-action--yellow .call-to-action__link.button:link, .call-to-action--yellow .call-to-action__link.button:visited {
    color: #fff
}

.call-to-action--orange {
    border-color: #ff7900
}

.call-to-action--orange .call-to-action__link:link, .call-to-action--orange .call-to-action__link:visited {
    color: #eb6d00
}

.call-to-action--orange .call-to-action__link.button {
    background-color: #eb6d00
}

.call-to-action--orange .call-to-action__link.button:link, .call-to-action--orange .call-to-action__link.button:visited {
    color: #fff
}

.call-to-action--red {
    border-color: #cd202c
}

.call-to-action--red .call-to-action__link:link, .call-to-action--red .call-to-action__link:visited {
    color: #cd202c
}

.call-to-action--red .call-to-action__link.button {
    background-color: #cd202c
}

.call-to-action--red .call-to-action__link.button:link, .call-to-action--red .call-to-action__link.button:visited {
    color: #fff
}

.call-to-action--gray {
    border-color: #333
}

.call-to-action--gray .call-to-action__link:link, .call-to-action--gray .call-to-action__link:visited {
    color: #333
}

.call-to-action--gray .call-to-action__link.button {
    background-color: #333
}

.call-to-action--gray .call-to-action__link.button:link, .call-to-action--gray .call-to-action__link.button:visited {
    color: #fff
}

.taxonomy-listing li {
    line-height: 1.2em;
    margin-bottom: .45em
}

.taxonomy-listing__list {
    line-height: 1
}

@media (min-width: 600px) {
    .taxonomy-listing__list {
        columns: 2;
        column-gap: 1.5em;
        min-height: 100%
    }
}

@media (min-width: 1400px) {
    .taxonomy-listing__list {
        columns: 3
    }
}

.taxonomy-listing__item {
    padding: 0;
    border: 0;
    break-inside: avoid;
    page-break-inside: avoid;
    display: table;
    line-height: 1.1
}

.taxonomy-listing__item a:link, .taxonomy-listing__item a:visited {
    display: block;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: .75em
}

.taxonomy-listing__level2 {
    margin: 0;
    padding-left: .75em;
    list-style: none
}

.taxonomy-listing__level2-item a:link, .taxonomy-listing__level2-item a:visited {
    font-weight: 400;
    margin-bottom: .75em
}

.taxonomy-listing__level3 {
    margin: 0;
    padding-left: .75em;
    list-style: none
}

.taxonomy-listing__level3-item {
    position: relative;
    padding-left: .525em
}

.taxonomy-listing__level3-item:before {
    position: absolute;
    left: 0;
    top: -.225em;
    content: "-";
    color: #00609c
}

.taxonomy-listing__level3-item a:link, .taxonomy-listing__level3-item a:visited {
    font-weight: 400
}

.taxonomy-listing__level4 {
    margin: 0;
    padding-left: .75em;
    list-style: none
}

.taxonomy-listing__level4-item {
    position: relative;
    padding-left: .75em
}

.taxonomy-listing__level4-item:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "--";
    color: #00609c
}

.featured-stories {
    position: relative
}

.featured-stories__slide img {
    margin: auto
}

section .section-gray .featured-stories {
    margin-bottom: 0;
    padding-bottom: 0
}

.status-messages {
    margin-bottom: 1.875em;
    margin-top: 1.875em
}

.status-messages p {
    margin-top: 0
}

.status-messages .messages__list {
    list-style: none;
    padding: 0;
    margin: 0
}

.status-messages .messages__item {
    background: #f2f2f2;
    border-top-width: 5px;
    border-top-style: solid;
    color: #666;
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    width: 100%
}

.status-messages .messages__item + .messages__item {
    margin-top: 1.125em
}

.status-messages .icon-background, .status-messages .message-text {
    height: 100%;
    vertical-align: top
}

.status-messages .icon-background {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: .75em 0;
    text-align: center;
    width: 3.375em
}

.status-messages .icon-background .fa {
    min-width: 32px
}

.status-messages .message-text {
    padding: 1.125em
}

.status-messages .messages--status .messages__item {
    border-color: #6da400
}

.status-messages .messages--status .messages__item .icon-background {
    color: #6da400
}

.status-messages .messages--warning .messages__item {
    border-color: #f07200
}

.status-messages .messages--warning .messages__item .icon-background {
    color: #f07200
}

.status-messages .messages--error .messages__item {
    border-color: #cd202c
}

.status-messages .messages--error .messages__item .icon-background {
    color: #cd202c
}

.action-links {
    list-style: none;
    padding: 0;
    margin: 1em 0
}

[dir=rtl] .action-links {
    margin-right: 0
}

.action-links li {
    display: inline-block;
    margin: 0 .3em
}

.action-links li:first-child {
    margin-left: 0
}

[dir=rtl] .action-links li:first-child {
    margin-left: .3em;
    margin-right: 0
}

.button-action {
    display: inline-block;
    line-height: 160%;
    padding: .2em .5em .3em;
    text-decoration: none
}

.button-action:before {
    content: "+";
    font-weight: 900;
    margin-left: -.1em;
    padding-right: .2em
}

[dir=rtl] .button-action:before {
    margin-left: 0;
    margin-right: -.1em;
    padding-left: .2em;
    padding-right: 0
}

ul.inline, ul.links.inline {
    display: inline;
    padding-left: 0
}

[dir=rtl] ul.inline, [dir=rtl] ul.links.inline {
    padding-right: 0;
    padding-left: 15px
}

ul.inline li {
    display: inline;
    list-style-type: none;
    padding: 0 .5em
}

ul.links a.is-active {
    color: #000
}

.indented {
    margin-left: 1em
}

[dir=rtl] .indented {
    margin-left: 0;
    margin-right: 1em
}

.forum__indent {
    margin-left: 2em
}

.forum__icon {
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    height: 17px;
    margin: 3px 9px 0 0
}

[dir=rtl] .forum__icon {
    float: right;
    margin: 3px 0 0 9px
}

.forum__topic-status--new {
    background-image: url(/themes/custom/anl_parent_theme/imgs/svg/star.svg);
    width: 18px
}

.forum__topic-status--hot {
    background-image: url(/themes/custom/anl_parent_theme/imgs/svg/fire.svg);
    width: 13px
}

.forum__topic-status--hot-new {
    background-image: url(/themes/custom/anl_parent_theme/imgs/svg/fire-star.svg);
    width: 33px
}

.forum__topic-status--sticky {
    background-image: url(/themes/custom/anl_parent_theme/imgs/svg/thumbtack.svg);
    width: 13px
}

.forum__topic-status--closed {
    background-image: url(/themes/custom/anl_parent_theme/imgs/svg/lock.svg);
    width: 15px
}

.forum table {
    border-collapse: collapse;
    border: 1px solid #f2f2f2;
    border-top: 3px solid #0082ca
}

.forum table th {
    font-size: 1em;
    line-height: 1.275em;
    text-align: left;
    padding-bottom: .375em;
    font-weight: 700;
    padding: 1rem
}

@media (min-width: 900px) {
    .forum table th {
        font-size: 1.26em;
        line-height: 1.30952em
    }
}

.forum table td {
    padding: 1rem;
    border-bottom: 1px solid #d1d1d1
}

.forum table tbody tr:nth-child(odd) {
    background-color: #f2f2f2
}

.forum table p {
    margin: 0
}

.forum-topic .action-links {
    width: 100%;
    min-width: 260px;
    padding: 0 1.5em;
    margin: 0 auto
}

@media (min-width: 900px) {
    .forum-topic .action-links {
        width: 90%;
        padding: 0
    }
}

@media (min-width: 1100px) {
    .forum-topic .action-links {
        max-width: 1250px
    }
}

.forum-topic .js-comment {
    padding: 1.5em
}

.forum-topic .forum-topic__comment--odd {
    background-color: #f2f2f2;
    border-left: 4px solid #d1d1d1
}

.forum-topic .forum-topic__comment--even {
    border: 1px solid #f2f2f2;
    border-left: 4px solid #d1d1d1
}

.forum-topic .forum-topic__comment-form-title {
    font-size: 1.26em;
    line-height: 1.30952em;
    margin-top: 7.5em
}

.forum-topic mark {
    margin-bottom: .75em;
    display: inline-block
}

.forum-topic .forum-topic__meta {
    font-size: .85em;
    line-height: 1.41176em
}

.forum-topic .comment-form input, .forum-topic .comment-form textarea {
    margin-bottom: 1.5em
}

.forum-topic .comment-form .form-actions {
    margin-top: 1.5em
}

.user-details {
    margin: 3em 0
}

.rail .paragraph--type--rich-text {
    font-size: .85em;
    line-height: 1.32353em
}

.paragraph--type--rich-text :first-child {
    margin-top: 0
}

.paragraph--type--content-listing {
    margin: .75em 0
}

.paragraph--type--content-listing .listing-view-list > article, .paragraph--type--content-listing .listing-view-list div.reference-teaser {
    margin-top: 1.125em;
    padding-bottom: 1.125em;
    border-bottom: 1px solid #f2f2f2
}

.paragraph--type--content-listing .listing-view-list > article:last-child, .paragraph--type--content-listing .listing-view-list div.reference-teaser:last-child {
    border-bottom: 0
}

@media (min-width: 600px) {
    .paragraph--type--content-listing .teaser--profile {
        padding: 0
    }
}

.l-3up .paragraph--type--content-listing .teaser__block, .paragraph--type--content-listing.rail .teaser__block {
    border-top: none;
    padding-top: 0
}

.l-3up .paragraph--type--content-listing .teaser--image .teaser__block.has-image, .paragraph--type--content-listing.rail .teaser--image .teaser__block.has-image {
    float: none;
    width: auto
}

.l-3up .paragraph--type--content-listing .teaser--image .teaser__image, .paragraph--type--content-listing.rail .teaser--image .teaser__image {
    display: none
}

.l-3up .paragraph--type--content-listing .related-teaser__title, .l-3up .paragraph--type--content-listing .teaser__title, .paragraph--type--content-listing.rail .related-teaser__title, .paragraph--type--content-listing.rail .teaser__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    line-height: 1.05em
}

.l-3up .paragraph--type--content-listing .teaser__label, .paragraph--type--content-listing.rail .teaser__label {
    display: none
}

.l-3up .paragraph--type--content-listing .teaser__meta, .paragraph--type--content-listing.rail .teaser__meta {
    font-size: .85em;
    line-height: 1.14706em;
    margin-top: .29762em;
    color: #a5a5a5
}

.l-3up .paragraph--type--content-listing .tags, .l-3up .paragraph--type--content-listing .teaser__desc, .paragraph--type--content-listing.rail .tags, .paragraph--type--content-listing.rail .teaser__desc {
    display: none
}

.l-3up .paragraph--type--content-listing .related-teaser__text, .paragraph--type--content-listing.rail .related-teaser__text {
    font-size: .85em;
    line-height: 1.23529em;
    margin-bottom: .375em
}

.l-3up .paragraph--type--content-listing .cta-link, .paragraph--type--content-listing.rail .cta-link {
    font-size: .7em;
    line-height: 1.39286em;
    display: block
}

.l-3up .paragraph--type--content-listing .cta-link svg, .paragraph--type--content-listing.rail .cta-link svg {
    width: 8px;
    height: 9px;
    fill: #666;
    margin-left: .3em
}

.l-3up .paragraph--type--content-listing .cta-link:active, .l-3up .paragraph--type--content-listing .cta-link:focus, .l-3up .paragraph--type--content-listing .cta-link:hover, .l-3up .paragraph--type--content-listing .cta-link:link, .l-3up .paragraph--type--content-listing .cta-link:visited, .paragraph--type--content-listing.rail .cta-link:active, .paragraph--type--content-listing.rail .cta-link:focus, .paragraph--type--content-listing.rail .cta-link:hover, .paragraph--type--content-listing.rail .cta-link:link, .paragraph--type--content-listing.rail .cta-link:visited {
    color: #666
}

.l-3up .paragraph--type--content-listing .contact {
    border-top: none;
    border-bottom: 1px solid #f2f2f2
}

.paragraph--type--content-listing.rail {
    border-top: 3px solid #a5a5a5 !important
}

.paragraph--type--content-listing.rail .rail-text, .paragraph--type--content-listing.rail .rail-text p, .paragraph--type--content-listing.rail .rail-title {
    margin: 0 0 .75em
}

.paragraph--type--content-listing.rail .rail-text p:last-child {
    margin: 0
}

.paragraph--type--content-listing.rail .rail-list {
    margin: .75em 0
}

.paragraph--type--content-listing.rail .rail-list article, .paragraph--type--content-listing.rail .rail-list div.reference-teaser, .paragraph--type--content-listing.rail .rail-list li {
    font-size: .85em;
    line-height: 1.23529em;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 0;
    padding: .75em 0
}

.paragraph--type--content-listing.rail .rail-list article:last-child, .paragraph--type--content-listing.rail .rail-list div.reference-teaser:last-child, .paragraph--type--content-listing.rail .rail-list li:last-child {
    border-bottom: none
}

.paragraph--type--content-listing .pager {
    border-top: 1px solid #d1d1d1
}

.paragraph--type--content-listing .pager .pager__item a:link, .paragraph--type--content-listing .pager .pager__item a:visited {
    font-size: .85em;
    line-height: 1.41176em;
    padding: .15em .45em;
    margin-bottom: .375em
}

.paragraph--type--content-listing .pager .fa {
    font-size: 13px
}

.field--body-layout-section {
    margin: 0 0 .75em
}

.l-main table {
    font-size: .85em;
    line-height: 1.32353em;
    border-collapse: collapse;
    border-radius: 3px;
    border-spacing: 0;
    border: 1px solid #ccc;
    color: #666;
    margin-bottom: 3em;
    overflow: auto;
    width: 100%
}

.l-main table tr:nth-child(odd) {
    background: #f2f2f2
}

.l-main table td, .l-main table th {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 15px 5px
}

.l-main table th {
    border-bottom: 3px solid #a5a5a5;
    color: #333;
    line-height: 1.15;
    text-align: left;
    vertical-align: bottom;
    word-break: break-word
}

.l-main table.data {
    text-shadow: 1px 1px 0 #fff
}

.l-main table.data th {
    background: linear-gradient(180deg, #eee, #e5e5e5)
}

.l-main table.data td {
    border-bottom: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    box-shadow: 0 1px 0 1px #f9f9f9
}

.l-main table.data tr.table-header th {
    border-left: 1px solid #ccc !important;
    vertical-align: bottom
}

.l-main table.data tr.table-header th.first {
    border-left: none !important
}

.l-main table.data tr:nth-child(odd) {
    background: #f2f2f2
}

.l-main table.data tbody > tr:last-child td, .l-main table.data tbody > tr:last-child th {
    border-bottom: none !important
}

.l-main table.demographics {
    font-size: .7em;
    line-height: 1.07143em
}

.l-main table.demographics th.first {
    width: 100px
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive:before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9:before {
    padding-top: 42.85714%
}

.embed-responsive-16by9:before {
    padding-top: 56.25%
}

.embed-responsive-4by3:before {
    padding-top: 75%
}

.embed-responsive-1by1:before {
    padding-top: 100%
}

.l-main .paragraph--type--profile-group {
    margin-bottom: 1.5em
}

.rail-paragraph .rail-block .profile-group, .rail-paragraph .rail-block .text-list {
    background-color: transparent;
    border: 0;
    padding: .675em 0 .75em;
    border-top: 3px solid #a5a5a5 !important
}

.rail-paragraph .rail-block .profile-group .rail-title, .rail-paragraph .rail-block .text-list .rail-title {
    margin-bottom: .75em
}

.rail-paragraph .rail-block .profile-group ol, .rail-paragraph .rail-block .profile-group ul, .rail-paragraph .rail-block .text-list ol, .rail-paragraph .rail-block .text-list ul {
    font-size: .85em;
    line-height: 1.32353em
}

.rail-paragraph .rail-block .profile-group {
    margin-bottom: 0;
    padding-bottom: 0
}



.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

.story-stat__number {
    letter-spacing: -.0125em
}

.list--simple {
    margin: 0;
    padding: 0;
    list-style: none
}

.clearfix, .project-teaser__block {
    clear: both
}

.clearfix:after, .project-teaser__block:after {
    content: "";
    display: table;
    clear: both
}

.element-focusable, .element-invisible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px)
}

.element-focusable:active, .element-focusable:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto
}

.nav-list__item a:active, .nav-list__item a:focus, .nav-list__item a:hover {
    color: #00609c
}

.site-search label {
    color: #d1d1d1
}

@media (min-width: 900px) {
    .search-trigger {
        left: 90%;
        right: 4.5em
    }

    .search-trigger svg {
        fill: #a5a5a5
    }

    .search-trigger:active, .search-trigger:focus, .search-trigger:hover {
        background-color: transparent
    }

    .search-trigger:active svg, .search-trigger:focus svg, .search-trigger:hover svg {
        fill: #333
    }
}

.site-search__form {
    background-color: #333
}

.CoveoSearchInterface {
    font-family: proxima-nova, Helvetica, Arial, Nimbus Sans L, sans-serif;
    font-size: inherit
}

.CoveoSearchInterface .coveo-main-section {
    font-size: 16px;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    position: unset
}

.CoveoSearchInterface .coveo-search-section {
    max-width: none;
    margin: 1.5em 0;
    border: 1px solid #d1d1d1;
    padding: 1.5em
}

.CoveoSearchInterface .coveo-search-section .l-2up, .CoveoSearchInterface .coveo-search-section .l-66-33--1 {
    margin-bottom: 1.5em
}

@media (min-width: 600px) {
    .CoveoSearchInterface .coveo-search-section .l-2up, .CoveoSearchInterface .coveo-search-section .l-66-33--1 {
        margin-bottom: 0
    }
}

.CoveoSearchInterface .CoveoSearchbox {
    margin-right: 0;
    max-width: none
}

.CoveoSearchInterface #SearchDropDown {
    padding: 0;
    margin-top: 0;
    position: static;
    right: 0;
    width: auto
}

.CoveoSearchInterface select.SearchContext {
    background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 98% 50%;
    background-repeat: no-repeat;
    font-weight: 400;
    text-transform: unset
}

.CoveoSearchInterface .SearchContextButton {
    background-color: #00609c;
    margin-left: 0
}

.CoveoSearchInterface .SearchContextButton:active, .CoveoSearchInterface .SearchContextButton:focus, .CoveoSearchInterface .SearchContextButton:hover {
    background-color: #0082ca;
    color: #fff;
    text-decoration: none;
    outline: none
}

.CoveoSearchInterface .coveo-results-column {
    padding: 10px 0
}

@media (min-width: 900px) {
    .CoveoSearchInterface .coveo-results-column {
        padding: 10px 10px 10px 0
    }
}

.CoveoSearchInterface .coveo-facet-column {
    margin-right: 0;
    padding-right: 0
}

.CoveoSearchInterface .CoveoFacet {
    margin: .375em 0 .75em;
    padding: .375em 0
}

.CoveoSearchInterface .coveo-facet-header {
    border-bottom: none;
    font-size: .85em;
    line-height: 1.32353em;
    list-style: none;
    background: transparent none repeat scroll 0 0;
    margin: .375em 0 0;
    padding: .375em 0;
    border-top: 3px solid #a5a5a5
}

.CoveoSearchInterface .coveo-facet-header-title {
    color: #333;
    font-size: 16px
}

.CoveoSearchInterface .coveo-facet-value:hover {
    background: none
}

.CoveoSearchInterface label.coveo-facet-value-label {
    color: #00609c;
    font-size: 16px;
    line-height: 1.3;
    padding: .15em 0;
    text-transform: none
}

.CoveoSearchInterface label.coveo-facet-value-label:hover {
    cursor: pointer;
    text-decoration: underline
}

.CoveoSearchInterface .coveo-facet-value-count {
    color: #666
}

.CoveoSearchInterface .coveo-facet-less:focus, .CoveoSearchInterface .coveo-facet-less:hover, .CoveoSearchInterface .coveo-facet-more:focus, .CoveoSearchInterface .coveo-facet-more:hover {
    background: none !important
}

.CoveoSearchInterface .coveo-result-frame.article .CoveoResultLink[role=heading], .CoveoSearchInterface .coveo-result-frame.default .CoveoResultLink[role=heading], .CoveoSearchInterface .coveo-result-frame.researchers .CoveoResultLink[role=heading] {
    font-size: 24px;
    font-weight: 600
}

.CoveoSearchInterface .CoveoFieldValue[data-field="@uri"] {
    color: #006621;
    font-size: 14px;
    line-height: 1
}

.utility-nav {
    background-color: #f2f2f2;
    border-bottom: 1px solid #e0e0e0
}

.utility-nav__list-item a:link, .utility-nav__list-item a:visited {
    color: #333
}

.basic-header.basic-header--image .basic-header__block, .basic-header.basic-header--video .basic-header__block {
    background-color: rgba(51, 51, 51, .8)
}

.basic-header__title-block .basic-header__acronym {
    color: #333
}

.rail-block {
    background-color: transparent;
    border: 0;
    padding: .675em 0 .75em;
    border-top: 3px solid #a5a5a5
}

.rail-title {
    color: #666
}

.rail-list li {
    border-color: #f2f2f2
}

.rail-title, .rail .contact__label, .rail .link-listing__title {
    font-weight: 700
}

.contact {
    background-color: transparent;
    border: 0;
    padding: .675em 0 .75em;
    border-top: 3px solid #a5a5a5
}

.contact__label {
    color: #666
}

.project-teaser {
    background-color: transparent;
    border: 0;
    margin: .75em 0
}

.project-teaser__block {
    border-top: 3px solid #a5a5a5;
    padding: .675em 0 .75em
}

.project-teaser__label {
    color: #666
}

.teaser__block {
    border-top: 3px solid #d1d1d1
}

.tags-footer__label {
    text-transform: uppercase
}

.related-teaser__block {
    border-top: 3px solid #d1d1d1;
    padding-top: .525em
}

.l-rail .related-teaser__block {
    border-top: 0;
    padding-top: 0
}

.related-teaser--org .related-teaser__block {
    padding-top: 0
}

.teaser--publication.citation .teaser__block {
    border-top: 3px solid #d1d1d1
}

.teaser--profile.teaser--featured {
    overflow: auto;
    margin-bottom: 1.5em;
    border-bottom: 1px solid #f2f2f2
}

@media (min-width: 600px) {
    .teaser--profile.teaser--featured {
        padding: 0
    }
}

.teaser--profile.teaser--featured .related-teaser__block {
    border-top: none;
    padding-top: 0
}

.paragraph--type--featured-profiles div div:last-child .teaser--profile.teaser--featured {
    border-bottom: none
}

.reference-teaser {
    border-top: 3px solid #d1d1d1;
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: .375em;
    margin: .75em 0 1.125em
}

.rail .reference-teaser {
    border-top: 0;
    padding-top: 0
}

.contact--main .reference-teaser, .contact .reference-teaser {
    padding-top: 0;
    margin: 0;
    border-top: 0
}

.rail-nav #rail-nav-list > li:first-child {
    background: #666;
    color: #fff
}

.rail-nav #rail-nav-list > li:first-child .rail-nav-sublist-trigger, .rail-nav #rail-nav-list > li:first-child a {
    color: #fff
}

.rail-nav #rail-nav-list > li:first-child .rail-nav-sublist {
    border-top: none
}

.rail-nav ul.rail-nav-sublist li {
    background: transparent !important;
    color: #333 !important
}

.rail-nav ul.rail-nav-sublist li a {
    color: #333 !important
}

.rail-nav-list__item:last-child > a:link, .rail-nav-list__item:last-child > a:visited {
    border-bottom: none
}

.rail-nav-sublist-trigger, .rail-nav-sublist-trigger:active, .rail-nav-sublist-trigger:focus, .rail-nav-sublist-trigger:hover {
    color: #333
}

.rail-nav-sublist-trigger:focus {
    border: 1px solid #333
}

.rail-nav-sublist {
    border-top: 3px solid #a5a5a5
}

.rail-nav-sublist__item > a:link, .rail-nav-sublist__item > a:visited {
    color: #333;
    display: block;
    padding: .375em .675em .375em .9em
}

.rail .link-listing__title {
    color: #666
}

.curated-block {
    border-top: 3px solid #a5a5a5
}

.l-rail .curated-block {
    background-color: transparent;
    padding: 0;
    border: 0;
    border-top: 0
}

.l-rail .curated-block .related-teaser__image {
    border-bottom: 3px solid #a5a5a5
}

.curated-block .related-teaser__image {
    border-top: 0
}

.curated-block .related-teaser__block, .listing-view-list .teaser--publication.citation .teaser__block {
    border-top: 0;
    padding-top: 0
}

.listing-view-list .reference-teaser {
    border-top: 0;
    padding-top: 0;
    margin-bottom: 0
}

.listing-view-list .related-teaser__block {
    border: 0;
    padding-top: 0
}

.image-block__overlay, .text-overlay {
    background-color: rgba(51, 51, 51, .85)
}

.story-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1.5em;
    border-bottom: 0
}

.story-header, .story-header .image-caption {
    margin-bottom: 0
}

.story-header__hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 91vh
}

@media (min-width: 600px) {
    .story-header__hero {
        height: 95vh
    }
}

@media (min-width: 900px) {
    .story-header__hero {
        height: 89vh
    }
}

.story-header__background {
    background-color: rgba(51, 51, 51, .7);
    padding: 50px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    width: 100%
}

@media (min-width: 900px) {
    .story-header__background {
        padding: 0;
        width: 60vh;
        height: 60vh;
        min-width: 550px;
        min-height: 550px;
        margin: auto;
        border-radius: 50%
    }
}

.story-header__block {
    color: #fff;
    text-align: center
}

.story-header__title {
    text-transform: uppercase;
    font-weight: 400
}

@media (min-width: 600px) {
    .story-header__title {
        font-size: 3.2em;
        line-height: 1.03125em
    }
}

@media (min-width: 900px) {
    .story-header__title {
        font-size: 4.5em;
        line-height: 1.08333em
    }
}

.story-header__title span {
    font-weight: 700
}

.story-header__subtitle {
    font-size: 1.26em;
    line-height: 1.30952em;
    margin-bottom: 0;
    margin-top: .15em;
    font-family: adobe-garamond-pro, Georgia, Times New Roman, DejaVu Serif, serif;
    font-style: italic;
    font-weight: 400
}

@media (min-width: 600px) {
    .story-header__subtitle {
        font-size: 1.57em;
        line-height: 1.19427em
    }
}

.oleo-hero {
    background-image: url(/sites/www/themes/custom/anl_public_theme/imgs/oleo-2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%
}

@media (min-width: 600px) {
    .story-2up {
        background-color: #f2f2f2;
        display: table;
        min-height: 350px
    }
}

.story-2up__text {
    padding: 0 1.5em;
    margin: 0 auto 1.5em
}

@media (min-width: 600px) {
    .story-2up__text {
        padding: 3em 1.5em;
        display: table-cell;
        width: 50%;
        margin: 0
    }
}

@media (min-width: 1100px) {
    .story-2up__text {
        padding: 3em
    }
}

.story-2up__image-caption {
    font-size: .7em;
    line-height: 1.5em;
    color: #666;
    margin-top: 3em;
    margin-bottom: 0
}

.story-2up__image-caption p {
    margin-bottom: 0
}

.story-2up__img {
    width: 100%;
    height: 250px
}

@media (min-width: 600px) {
    .story-2up__img {
        width: 50%;
        height: 100%;
        display: table-cell
    }
}

.oleo-1 {
    background-image: url(/sites/www/themes/custom/anl_public_theme/imgs/oleo-1.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%
}

.story-stat {
    color: #f2f2f2;
    text-align: center;
    position: relative;
    font-family: adobe-garamond-pro, Georgia, Times New Roman, DejaVu Serif, serif;
    font-style: italic;
    background-color: #000;
    border-radius: 50%;
    width: 90%;
    margin: 1.5em auto;
    display: block
}

@media (min-width: 425px) {
    .story-stat {
        width: 260px;
        margin: 1.5em
    }
}

@media (min-width: 900px) {
    .story-stat {
        font-size: .9em;
        line-height: 1.33333em
    }
}

.story-stat:after {
    content: "";
    display: block;
    padding-bottom: 100%
}

.story-stat p {
    margin: auto
}

@media (min-width: 425px) {
    .story-stat.story-stat--left {
        float: left;
        margin-left: 0;
        shape-outside: circle(53% at 46%)
    }
}

@media (min-width: 900px) {
    .story-stat.story-stat--left {
        margin-left: -130px;
        shape-outside: circle(65% at 0)
    }
}

@media (min-width: 425px) {
    .story-stat.story-stat--right {
        float: right;
        margin-right: 0;
        shape-outside: circle(53% at 54%)
    }
}

@media (min-width: 900px) {
    .story-stat.story-stat--right {
        margin-right: -130px;
        shape-outside: circle(65% at 100%)
    }
}

.story-stat__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5em;
    -ms-flex-align: center;
    align-items: center
}

.story-stat__number {
    font-size: 4.5em;
    line-height: 1.08333em;
    font-weight: 100;
    font-family: proxima-nova, Helvetica, Arial, Nimbus Sans L, sans-serif;
    font-style: normal;
    display: block;
    margin-top: -.1em
}

.story-lede {
    font-size: 1.26em;
    line-height: 1.30952em;
    color: #666
}

.story-lede:first-letter {
    font-size: 1.9em;
    line-height: 1.14474em;
    color: #0082ca;
    border: 4px solid #f2f2f2;
    padding: .03em .225em;
    margin: 0 .225em .225em 0;
    display: block;
    float: left
}

@media (min-width: 425px) {
    .story-lede:first-letter {
        font-size: 2.2em;
        line-height: 1.15909em;
        margin: .105em .225em .225em 0;
        font-size: 3.2em;
        line-height: 1.03125em
    }
}

@media (min-width: 900px) {
    .story-lede:first-letter {
        font-size: 4.5em;
        line-height: 1.08333em
    }
}

.story-image-drawer {
    margin-bottom: 3em
}

.story-image-drawer__wrapper {
    position: relative
}

.story-image-drawer__figure {
    margin: 0
}

.story-image-drawer__figure img {
    display: block
}

.story-image-drawer__trigger {
    position: absolute;
    bottom: -1.8rem;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center
}

.story-image-drawer__trigger a {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none;
    font-size: 1.5em
}

.story-image-drawer__trigger a:active, .story-image-drawer__trigger a:active i, .story-image-drawer__trigger a:focus, .story-image-drawer__trigger a:focus i, .story-image-drawer__trigger a:hover, .story-image-drawer__trigger a:hover i {
    text-decoration: none
}

.story-image-drawer__trigger a i {
    margin: auto;
    text-decoration: none
}

.story-image-drawer__content {
    padding-top: 1.5em
}

.story-image-drawer__content p {
    font-size: .85em;
    line-height: 1.41176em
}

.story-annotated-image {
    position: relative
}

.story-annotated-image__figure {
    margin: 0;
    padding: 0;
    position: relative
}

.story-annotated-image__figure img {
    display: block;
    width: 100%
}

.story-annotated-image__trigger {
    font-size: .85em;
    line-height: 1.41176em;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #fff;
    text-decoration: none;
    font-weight: 700;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: transform 1s
}

@media (min-width: 600px) {
    .story-annotated-image__trigger {
        font-size: 1em;
        line-height: 1.5em;
        width: 2.2rem;
        height: 2.2rem
    }
}

.story-annotated-image__trigger.js-trigger--active, .story-annotated-image__trigger:active, .story-annotated-image__trigger:hover {
    text-decoration: none
}

@media (min-width: 600px) {
    .story-annotated-image__trigger.js-trigger--active, .story-annotated-image__trigger:active, .story-annotated-image__trigger:hover {
        transform: scale(1.2)
    }
}

.story-annotated-image__trigger.js-trigger--active {
    background-color: #00609c;
    color: #fff
}

.story-annotated-image__cta {
    margin-top: .75em;
    padding-bottom: .75em;
    border-bottom: 1px solid #d1d1d1
}

.story-annotated-image__cta-title {
    font-size: 1em;
    line-height: 1.2em;
    margin-top: 0;
    text-transform: uppercase
}

.story-annotated-image__cta-desc {
    font-size: .85em;
    line-height: 1.32353em;
    margin: 0
}

.story-annotated-image__desc {
    position: relative;
    margin-top: 1.5em
}

.story-annotated-image__desc h2 {
    font-size: 1em;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: .225em;
    text-transform: uppercase;
    padding-left: 2.5rem
}

.story-annotated-image__desc p {
    font-size: .85em;
    line-height: 1.32353em;
    padding-left: 2.5rem
}

.story-annotated-image__marker {
    line-height: 0;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 1rem .75rem;
    background-color: #00609c;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    float: left
}

.featured-stories {
    background-color: #f2f2f2;
    padding: 1.5em;
    margin-bottom: 1.875em
}

.featured-stories__slide {
    margin: 0 .225em
}

.featured-stories__slide img {
    margin: auto
}

.featured-stories__slide .tags {
    margin-bottom: 1.5em
}

.inline .maintenance {
    text-align: center
}

.inline .maintenance .header__brand {
    display: block;
    width: 100%
}

.inline .maintenance .basic-header__block {
    margin-top: 2.25em
}
#create-associate input,
#add-a-system,
#add-a-system input {
    margin: 10px 0;
}
#create-associate {
    margin-bottom: 10px;
}

@media (min-width: 600px) {
    .inline .maintenance {
        text-align: left
    }
}

.embedded-entity {
    float: none;
    width: 100%;
    margin: .525em 0
}

@media (min-width: 600px) {
    .embedded-entity {
        width: 50%;
        float: left;
        margin: .225em 1.5em .75em 0
    }
}

.embedded-entity img {
    height: auto;
    width: 100%
}

.embedded-entity.align-center {
    float: none;
    width: 100%;
    margin: .375em 0
}

.embedded-entity.align-portrait {
    float: left;
    width: 50%;
    margin: .225em 1.5em .75em 0
}

@media (min-width: 600px) {
    .embedded-entity.align-portrait {
        width: 30%
    }
}

@media (min-width: 900px) {
    .embedded-entity.align-portrait {
        width: 25%
    }
}

@media (min-width: 600px) {
    .embedded-entity.align-right {
        float: right;
        margin: .225em 0 .75em 1.5em
    }
}

figure > .embedded-entity {
    float: none;
    width: auto;
    margin: initial
}

figure.caption, figure.inline-image {
    float: none;
    width: 100%;
    margin: .525em 0
}

@media (min-width: 600px) {
    figure.caption, figure.inline-image {
        width: 50%;
        float: left;
        margin: .225em 1.5em .75em 0
    }
}

.wysiwyg_align-center, .wysiwyg_align-left, .wysiwyg_align-portrait, .wysiwyg_align-right, blockquote, figure.caption.align-center, figure.caption.align-left, figure.caption.align-portrait, figure.caption.align-right, figure.inline-image.wysiwyg_align-center, figure.inline-image.wysiwyg_align-left, figure.inline-image.wysiwyg_align-portrait, figure.inline-image.wysiwyg_align-right {
    float: none;
    width: 100%;
    margin: .525em 0
}

.wysiwyg_align-center img, .wysiwyg_align-left img, .wysiwyg_align-portrait img, .wysiwyg_align-right img, blockquote img, figure.caption.align-center img, figure.caption.align-left img, figure.caption.align-portrait img, figure.caption.align-right img, figure.inline-image.wysiwyg_align-center img, figure.inline-image.wysiwyg_align-left img, figure.inline-image.wysiwyg_align-portrait img, figure.inline-image.wysiwyg_align-right img {
    width: 100%
}

@media (min-width: 600px) {
    .wysiwyg_align-center, .wysiwyg_align-left, .wysiwyg_align-portrait, .wysiwyg_align-right, blockquote, figure.caption.align-center, figure.caption.align-left, figure.caption.align-portrait, figure.caption.align-right, figure.inline-image.wysiwyg_align-center, figure.inline-image.wysiwyg_align-left, figure.inline-image.wysiwyg_align-portrait, figure.inline-image.wysiwyg_align-right {
        margin: .375em 0
    }
}

@media (min-width: 600px) {
    .wysiwyg_align-left, figure.caption.align-left, figure.inline-image.wysiwyg_align-left {
        width: 50%;
        float: left;
        margin: .225em 1.5em .75em 0
    }
}

@media (min-width: 600px) {
    .wysiwyg_align-right, figure.caption.align-right, figure.inline-image.wysiwyg_align-right {
        width: 50%;
        float: right;
        margin: .225em 0 .75em 1.5em
    }
}

.wysiwyg_align-portrait, figure.caption.align-portrait, figure.inline-image.wysiwyg_align-portrait {
    width: 50%;
    float: left;
    margin: .225em 1.5em .75em 0
}

@media (min-width: 600px) {
    .wysiwyg_align-portrait, figure.caption.align-portrait, figure.inline-image.wysiwyg_align-portrait {
        width: 30%
    }
}

@media (min-width: 900px) {
    .wysiwyg_align-portrait, figure.caption.align-portrait, figure.inline-image.wysiwyg_align-portrait {
        width: 25%
    }
}

@media print {
    #block-tabs.contextual-region, .footer-newsletter, .footer__bottom .footer-doe__list, .footer__bottom .footer-nav-list, .footer__middle, .greeting, .nav-drawer, .nav-trigger, .rail-nav, .search-trigger, .site-search, .social-media, .tags, .utility-nav, .video-embed-field-responsive-video {
        display: none
    }

    .page-wrapper {
        margin: 0
    }

    .related-main__list {
        font-size: 80%
    }

    .related-main__list li:nth-of-type(odd) {
        width: 49.15254%;
        clear: right;
        float: left;
        margin-left: 0;
        margin-right: 1.69492%;
        clear: both
    }

    .related-main__list li:nth-of-type(2n) {
        width: 49.15254%;
        clear: right;
        float: right;
        margin-right: 0
    }

    .footer {
        border-top: 0
    }

    .footer .footer-address {
        font-size: 12px;
        line-height: 18px;
        float: left;
        margin-right: 20px
    }

    .footer .container {
        width: 100%;
        clear: none
    }

    .footer .footer__bottom, .footer .footer__top {
        clear: none
    }

    .footer .footer__bottom:after, .footer .footer__top:after {
        content: none;
        display: none;
        clear: none
    }

    .footer .footer__bottom .l-2up, .footer .footer__top .l-2up {
        width: auto;
        clear: none;
        float: left
    }

    .footer .footer__bottom {
        background: none;
        border-top: 0;
        padding: 0
    }

    .footer .footer__bottom .container {
        border-top: 1px solid #f2f2f2;
        padding-top: 20px
    }

    .footer .footer__bottom .l-66-33--1, .footer .footer__bottom .l-66-33--2 {
        width: auto;
        clear: none;
        float: left
    }

    .footer .footer__bottom .footer-doe-logo {
        width: auto
    }

    .footer .footer__bottom .footer-doe-logo img {
        width: 100px;
        height: 26px;
        margin-right: 20px
    }

    .header {
        position: static !important;
        border-top: 0;
        padding-top: 0;
        border-bottom: 0
    }

    .header .logo svg {
        height: 37px;
        width: 125px
    }

    .header.sticky {
        position: static !important;
        padding-top: 0
    }

    @page {
        margin: .5cm
    }

    @page :left {
        margin: .5cm
    }

    @page :right {
        margin: .8cm
    }

    .article__byline, .lede {
        font-size: 14px;
        line-height: 20px
    }

    body, html {
        font-size: 16px;
        line-height: 20px
    }

    h1 {
        font-size: 30px;
        line-height: 38px
    }

    h2 {
        font-size: 28px;
        line-height: 34px
    }

    h3 {
        font-size: 24px;
        line-height: 32px
    }

    h4 {
        font-size: 22px;
        line-height: 30px
    }

    h5, h6 {
        font-size: 18px;
        line-height: 24px
    }



    .link-listing, .taxonomy-listing {
        background: none;
        padding: 0
    }

    .teaser--profile {
        page-break-inside: avoid
    }
}


/* PHONES */
@media only screen and (max-width: 767px) {
    .cels .account-readonly ul li {float: none;}
    .cels.explorer-page.mobile .sshPublicKeys-wrapper table tr td:first-child{width:inherit}
    body.cels.explorer-page.mobile .card-body {text-align:inherit}
    body.cels.explorer-page.mobile {margin-bottom:0}
    body.cels.explorer-page.mobile .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 9;
        top: 0;
        left: 0;
        background-color: #f9f9f9;
        overflow-x: hidden;
        transition: 0.5s;
        font-size:110%
    }


    body.cels.explorer-page.mobile .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        z-index: 999
    }


    body.cels.explorer-page.mobile .container {
        padding:0 .5em
    }

    body.cels.explorer-page.mobile .col-form {
        margin-top: 0;
        width: inherit
    }


    .cels.mobile .navbar-fixed-top, .cels.mobile .navbar {
        background: #fff
    }

    .cels.mobile .navbar-default .navbar-toggle {
        top: 15px;
        right: 15px;
        left: inherit;
        position: absolute;
        padding: 0;
        margin: 0;
    }

    .cels.mobile .list-inline > li.mobile {
        color: #ccc;
    }

    .cels.mobile .col-tree,
    .cels.mobile .col-form {
        width: 100%;
        margin: 0;
        float:left
    }

    .cels.mobile .user-explorer .col-tree {
        position: relative;
    }

    body.cels.mobile.explorer-page, body.cels.mobile.explorer-page .user-explorer .acctInfo {
        background: linear-gradient(90deg, #ffffff 0%, #fff 0%);
    }

    .mobile .navbar-default .navbar-nav > li > a, .mobile .navbar-default .navbar-brand, .mobile .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: inherit
    }

    .cels.mobile .header-section,
    .cels.mobile .navbar .navbar-collapse {
        float: none;
        width: inherit;
        background-color: #fff
    }


    .cels.mobile .nav-trigger svg {
        fill: #333;
        height: 33px;
        width: 18px
    }

    .cels.mobile .navbar {
        height: 40px;
        background-color: inherit
    }

    .cels.mobile .header__brand {
        padding: 10px 0 0 4em
    }
}

@media only screen and (min-width: 768px) {

    .navbar .navbar-collapse .navbar-nav li {
        padding-right: 0
    }

    .cels .navbar .navbar-collapse .nav-list__item {
        line-height: 1.58824em;
        padding: 0 .375em;
        border-bottom: 0;
    }
}

.cels .hyphenate {
    /* Careful, this breaks the word wherever it is without a hyphen */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* Adds a hyphen where the word breaks */
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    border:1px solid red;
    width:50%
}

.cels sshPublicKeys-wrapper .container {width:50%

}
  

/* TABLET AND LARGER  */
@media only screen and (min-width: 768px) {
    .public-page .container-fluid {
        width:100%;
        padding:0;
    }
    .container-fluid p {
        font-size: 20px;
    }

    .home-body .home-quick-links {
        text-align:left;
        background-color:#343965;
        border-radius:5px;
        padding:20px;
    }


    .btn.btn-square {
        border-radius:0;
    }

    nav .navbar-nav .dropdown-menu {
        text-align:left;
        position:absolute;
        color:#fff;
        background-color:#343965;
    }

    .container-fluid > .navbar-collapse {
        min-width:768px;
    }

    .container-fluid h1 {
        margin:30px 20px 0 10px;
    }


    .home-body .home-quick-links a,.reversed-white {
        color:#fff;
    }


}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
body.lcf {
    color: #4a4a4a;
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    min-height: 600px
}
body.public-page {
    /*background-image: url(/frontend/assets/img/home-blue-bckgrn.png);*/
    background-repeat: repeat-x;
    background-position: 0 182px;
}
/*.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}*/

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.container-fluid p {
    font-size: 16px;
    margin: 10px 20px 30px 10px;
}


/* _index.css */

.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */ }
  .ngx-datatable [hidden] {
    display: none !important; }
  .ngx-datatable *,
  .ngx-datatable *:before,
  .ngx-datatable *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .ngx-datatable.scroll-vertical .datatable-body {
    overflow-y: auto; }
  .ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
    position: absolute; }
  .ngx-datatable.scroll-horz .datatable-body {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
    white-space: nowrap; }
    .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .ngx-datatable.fixed-row .datatable-scroll {
    white-space: nowrap; display: inherit !important;}
    .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
      white-space: nowrap; }
      .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
  .ngx-datatable .datatable-body-row,
  .ngx-datatable .datatable-row-center,
  .ngx-datatable .datatable-header-inner {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    -webkit-flex-flow: row;
    -moz-flex-flow: row;
    -ms-flex-flow: row;
    -o-flex-flow: row;
    flex-flow: row; }
  .ngx-datatable .datatable-body-cell,
  .ngx-datatable .datatable-header-cell {
    overflow-x: hidden;
    vertical-align: top;
    display: inline-block;
    line-height: 1.625; }
    .ngx-datatable .datatable-body-cell:focus,
    .ngx-datatable .datatable-header-cell:focus {
      outline: none; }
  .ngx-datatable .datatable-row-left,
  .ngx-datatable .datatable-row-right {
    z-index: 9; }
  .ngx-datatable .datatable-row-left,
  .ngx-datatable .datatable-row-center,
  .ngx-datatable .datatable-row-group,
  .ngx-datatable .datatable-row-right {
    position: relative; }
  .ngx-datatable .datatable-header {
    display: block;
    overflow: hidden; }
    .ngx-datatable .datatable-header .datatable-header-inner {
      align-items: stretch;
      -webkit-align-items: stretch; }
    .ngx-datatable .datatable-header .datatable-header-cell {
      position: relative;
      display: inline-block; }
      .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
        cursor: pointer; }
      .ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
        cursor: move; }
      .ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
        line-height: 100%;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer; }

        .ngx-datatable .sortable .sort-btn:before {
            font-family: data-table;
            content: "c";
        }
        .ngx-datatable .sortable .sort-btn.datatable-icon-down:before {
            content: "f";
        }
        .ngx-datatable .sortable .sort-btn.datatable-icon-up:before {
            content: "e";
        }

      .ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
      .ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        padding: 0 4px;
        visibility: visible !important;
      }
      .ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
        cursor: ew-resize; visibility: visible; border-right: 1px solid #aaa;}
      .ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
        visibility: visible; }
      .ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
        visibility: visible; }
      .ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
        position: absolute;
        top: 0;
        bottom: 0; }
        .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
          right: 0; }
        .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
          left: 0; }
      .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
        height: inherit; }
  .ngx-datatable .datatable-body {
    position: relative;
    z-index: 10;
    display: block; }
    .ngx-datatable .datatable-body .datatable-scroll {
      display: inline-block; }
    .ngx-datatable .datatable-body .datatable-row-detail {
      overflow-y: hidden; }
    .ngx-datatable .datatable-body .datatable-row-wrapper {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
    .ngx-datatable .datatable-body .datatable-body-row {
      outline: none; }
      .ngx-datatable .datatable-body .datatable-body-row > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; }
  .ngx-datatable .datatable-footer {
    display: block;
    width: 100%;
    overflow: auto; }
    .ngx-datatable .datatable-footer .datatable-footer-inner {
      display: flex;
      align-items: center;
      width: 100%; }
    .ngx-datatable .datatable-footer .selected-count .page-count {
      flex: 1 1 40%; }
    .ngx-datatable .datatable-footer .selected-count .datatable-pager {
      flex: 1 1 60%; }
    .ngx-datatable .datatable-footer .page-count {
      flex: 1 1 20%; }
    .ngx-datatable .datatable-footer .datatable-pager {
      flex: 1 1 80%;
      text-align: right; }
      .ngx-datatable .datatable-footer .datatable-pager .pager,
      .ngx-datatable .datatable-footer .datatable-pager .pager li {
        padding: 0;
        margin: 0;
        display: inline-block;
        list-style: none; }
      .ngx-datatable .datatable-footer .datatable-pager .pager li,
      .ngx-datatable .datatable-footer .datatable-pager .pager li a {
        outline: none; }
      .ngx-datatable .datatable-footer .datatable-pager .pager li a {
        cursor: pointer;
        display: inline-block; }
      .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
        cursor: not-allowed; }


/*
bootstrap table theme
*/
  .ngx-datatable.bootstrap {
    box-shadow: none;padding:0;
    font-size: 13px; }
  .ngx-datatable.bootstrap .datatable-header {
    height: unset !important; }
  .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
    vertical-align: bottom;
    padding: 0.625rem;
    border-bottom: 1px solid #d1d4d7; }
  .ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
    line-height: 24px; }
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    vertical-align: top;
    border-top: 1px solid #d1d4d7; }
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    background-color: rgba(0, 0, 0, 0.05); }
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
    background-color: #1483ff;
    color: #fff; }
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top; }
  .ngx-datatable.bootstrap .datatable-body .empty-row {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0; }
  .ngx-datatable.bootstrap .datatable-footer {
    background: #424242;
    color: #ededed;
    margin-top: -1px; }
  .ngx-datatable.bootstrap .datatable-footer .page-count {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem; }
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager {
    margin: 0 10px;
    vertical-align: top; }
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
    margin: 10px 0px; }
    .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
      background-color: #545454;
      font-weight: bold; }
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 24px;
    line-height: 22px;
    padding: 0;
    border-radius: 3px;
    margin: 0 3px;
    text-align: center;
    vertical-align: top;
    text-decoration: none;
    vertical-align: bottom;
    color: #ededed; }
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
    font-size: 18px;
    line-height: 27px;
    padding: 0 3px; }
  .ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
    font-weight: bold; }

/* icons.css */

    @font-face {
      font-family: 'data-table';
      src: url('./../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.eot');
      src: url('./../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.eot?#iefix') format('embedded-opentype'),
        url('./../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.woff') format('woff'),
        url('./../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.ttf') format('truetype'),
        url('./../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.svg#data-table') format('svg');
      font-weight: normal;
      font-style: normal;
    }

    [data-icon]:before {
      font-family: 'data-table' !important;
      content: attr(data-icon);
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    [class^='datatable-icon-']:before,
    [class*=' datatable-icon-']:before {
      font-family: 'data-table' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .datatable-icon-filter:before {
      content: '\62';
    }
    .datatable-icon-collapse:before {
      content: '\61';
    }
    .datatable-icon-expand:before {
      content: '\63';
    }
    .datatable-icon-close:before {
      content: '\64';
    }
    .datatable-icon-up:before {
      content: '\65';
    }
    .datatable-icon-down:before {
      content: '\66';
    }
    .datatable-icon-sort:before {
      content: '\67';
    }
    .datatable-icon-done:before {
      content: '\68';
    }
    .datatable-icon-done-all:before {
      content: '\69';
    }
    .datatable-icon-search:before {
      content: '\6a';
    }
    .datatable-icon-pin:before {
      content: '\6b';
    }
    .datatable-icon-add:before {
      content: '\6d';
    }
    .datatable-icon-left:before {
      content: '\6f';
    }
    .datatable-icon-right:before {
      content: '\70';
    }
    .datatable-icon-skip:before {
      content: '\71';
    }
    .datatable-icon-prev:before {
      content: '\72';
    }




/*.datatable-header-cell-template-wrap span {
  font-weight: 600;
  cursor: pointer;
  } */

  /*.ngx-datatable.bootstrap .datatable-footer{ background:none;color:inherit}
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li a {background:#007bff}
  .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a {
      background-color: #00c851;
  }
   .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
      background-color: #0275d8;
  } */

  #loading-overlay {
    background: #ffffff;
    color: #666666;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    opacity: .80;
    margin-top: 5px;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0px 5px 40px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin .8s linear infinite; /* Safari */
  animation: spin .8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.wordWrap {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

.userAdmnComp .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
    text-align: center;
}
.userAdmnComp .ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.adminOtherAcct.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjSearchResults.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjAcMemTable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjauTable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#projTable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjau.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjAcTable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjAllocTable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#pjTable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
#admFavorRecords.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.otherAccTable .ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
    cursor: none;  border-right: 0;
}

.userAdmnComp .ngx-datatable ub3date{
    z-index: 9;
}

div.indentright ngb-typeahead-window {
    max-height: 200px;
    overflow-y: scroll;
    z-index: 9999;
    border-bottom-width: 3px;
    width: 300px;
}
select {
    -webkit-appearance: menulist;
}

.search-result-table .ngx-datatable ub3date input{
    background-color: white;
    padding-top:3px;
    padding-bottom: 3px;
    text-align: center;
    width: fit-content;
}
ngx-datatable#admEditRecord2 > div > datatable-body > datatable-selection > datatable-scroller > datatable-row-wrapper > datatable-body-row > div.datatable-row-center.datatable-row-group > datatable-body-cell:nth-child(1){
    padding-right: 20px;
}

#otherAccDeactDt input, #usrDeactDt input {
    border: none;
    border-right: 2px solid #ced4da;
    padding: 6px;
    background-color: white;
    padding-right: 12px;
}
#otherAccDeactDt img, usrDeactDt img {
    padding-left: 12px;
    padding-right: 6px;
}
.otherAccTable, .otherAccTable .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
    text-align: center;
}
#pjSearchResults.ngx-datatable.bootstrap .datatable-footer,
#pjauTable.ngx-datatable.bootstrap .datatable-footer,
#projTable.ngx-datatable.bootstrap .datatable-footer,
#pjau.ngx-datatable.bootstrap .datatable-footer,
#pjTable.ngx-datatable.bootstrap .datatable-footer,
#pjAcTable.ngx-datatable.bootstrap .datatable-footer,
#pjAllocTable.ngx-datatable.bootstrap .datatable-footer,
#admFavorRecords.ngx-datatable.bootstrap .datatable-footer,
#allocations.ngx-datatable.bootstrap .datatable-footer {
    display: none;
}
#admEditRecord2.ngx-datatable.bootstrap .datatable-footer {
    z-index: -1;
}
.otherAccTable > ngx-datatable > div > datatable-header{
    font-size: 12pt;
    font-weight: 600;
}
#projectList-header, #projectEdit-header{
    padding: 0;
}
#projectList-header button, #projectEdit-header button{
    color: black;
    text-decoration: underline;
}
#projTable.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label,
#pjau.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label,
#pjTable.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label,
#unixGroup.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label,
#pjAcTable.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label,
#pjAllocTable.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label{
    font-size: 16px;
    font-weight: 700;
}
#projMemStatTable ngx-datatable span.datatable-header-cell-label,
#pjAcTable.ngx-datatable.bootstrap span.datatable-header-cell-label{
    white-space:pre-wrap;
}
#systemMessage {
    text-align: center;
    background-color: #CC3525;
    color: #fff;
}
#pjAcMemTable.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.75rem 0.4rem;
}
#accAclList.ngx-datatable .datatable-footer,
#projAclList.ngx-datatable .datatable-footer,
#ugAclList.ngx-datatable .datatable-footer,
#sysAclList.ngx-datatable .datatable-footer {
    display: none;
}

#accAclList, #projAclList, #ugAclList, #sysAclList {
    margin-bottom: 20px;
}

/*classes for NG bootstrap v13 */
body .modal {
    z-index: 1200
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
}


.btn-close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

button.btn-close {
    background-color: transparent;
    border: 0;

}

.btn-close::after {
    content: "x";
}

/*ADDED for bootstrap 5.3.3 */
body {
    .table th, .table td {
        padding: .75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        background: none;
    }
    .form-control {
    height: calc(1.5em + .75rem + 2px);
}

    .btn-success {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
    }

    a {
        text-decoration: inherit

    /* Override .table > :not(caption) > * > * {*/
    }
   .table .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

    .table a, .table ul, .table span,  .table li {padding:0}
    .table h3, .table h4 {background:none}

    .ngb-dp-week.ngb-dp-weekdays {
        .ngb-dp-weekday {
            color: #000;
        }
        div.ngb-dp-weekday:first-child {
            right: 0;
            position: absolute;
        }
    }
}

