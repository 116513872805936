
body.public-page {

    .app-sidebar.navbar {
      display: block;
      width: 260px;
      text-transform: inherit;
      background-color: #0061af !important;
      color: #fff;
  
      .navbar-collapse {
        background-color: #0061af;
        color: #fff
      }
    }
  
  
  
    .app-sidebar {
      background-color: #0061af !important;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 998;
      height: 100vh;
      height: calc(1vh * 100);
      height: calc(var(--vh, 1vh) * 100);
      box-shadow: 6px 0px 20px 0 rgb(25 42 70 / 35%);
      transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
      white-space: nowrap;
      visibility: visible;
  
      .fa-chevron-down {
        // padding-left: 10px
      }
    }
  
    .app-sidebar .navbar-nav {
      .nav-link {
        color: #fff;
        background-color: #0061af;
      }
  
      .dropdown-menu {
        background-color: #0061af;
        box-shadow: none;
        position: relative;
        //   display:block
      }
    }
  
    .app-sidebar .navbar-nav > li {
      float: none
    }
  
    .navbar.app-sidebar.mobile {
      position: relative;
      height: auto;
        width:100%
    }
  
      footer.mobile {
      padding-left: 0
  }
  
  /*.dropdown-item:hover, */
    .dropdown-item:focus {
      color:#ffffff;
      background-color:#1e1651;
    }
  
  }
  
  .admin-user.navbar-default {
      display: block;
  }
  
  
  